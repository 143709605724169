export namespace BusinessScheduler {
  export class Get {
    static readonly type = '[BusinessScheduler] Get';
  }

  export class Success {
    static readonly type = '[BusinessScheduler] Success';
    constructor(public businessIsOpen: boolean) {}
  }

  export class Failure {
    static readonly type = '[BusinessScheduler] Failure';
    constructor(public message: string) {}
  }
}
