<div class="row mat-elevation-z1" (click)="openProduct(product.productId)">
  <div
    class="col"
    [ngClass]="product.urlImage?.length > 0 ? 'col-xs-7' : 'col-xs-12'"
  >
    <div class="row">
      <div class="col-xs-12">
        <div>
          <span class="name">{{ product.name }}</span>
        </div>
        <div>
          <p class="wrap-text">{{ product.description }}</p>
        </div>
        <app-product-label-discount
          [product]="product"
        ></app-product-label-discount>
      </div>
    </div>
  </div>
  <div class="col col-xs-5 section-image" *ngIf="product.urlImage?.length > 0">
    <img [src]="product.urlImage" [alt]="product.name" />
  </div>
</div>
