import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@material/material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { LoadingComponent } from '@shared/components/loading/loading.component';
import { ErrorComponent } from '@shared/components/error/error.component';
import { NotFoundComponent } from '@shared/pages/not-found/not-found.component';
import { CustomerInfoBottomSheetComponent } from './components/customer-info-bottom-sheet/customer-info-bottom-sheet.component';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';

@NgModule({
  declarations: [
    LoadingComponent,
    ErrorComponent,
    NotFoundComponent,
    CustomerInfoBottomSheetComponent,
    PrivacyPolicyDialogComponent,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  exports: [LoadingComponent, ErrorComponent, NotFoundComponent],
})
export class SharedModule {}
