<div class="row">
  <div class="col-xs-9">
    <mat-list>
      <div mat-subheader>Tipo de orden</div>
      <mat-list-item>
        <mat-icon mat-list-icon *ngIf="orderType === 'HERE'"
          >restaurant</mat-icon
        >
        <mat-icon mat-list-icon *ngIf="orderType === 'DELIVERY'"
          >delivery_dining</mat-icon
        >
        <mat-icon mat-list-icon *ngIf="orderType === 'PICK_UP'"
          >storefront</mat-icon
        >
        <div mat-line (click)="openOrderTypeSelector()">
          {{ orderType | orderType }}
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col-xs-3 change">
    <span (click)="openOrderTypeSelector()">Cambiar</span>
  </div>
</div>
<div class="row">
  <div class="col-xs-9" *ngIf="orderType === 'DELIVERY' && isValidInformation">
    <mat-list>
      <div mat-subheader>Dirección de entrega</div>
      <mat-list-item>
        <mat-icon mat-list-icon>face</mat-icon>
        <div mat-line (click)="openPersonalInfo()">
          {{ personalInfo.customerName }}
        </div>
        <div mat-line (click)="openPersonalInfo()">
          {{ personalInfo.phone }}
        </div>
        <div mat-line (click)="openPersonalInfo()">
          {{ personalInfo.street }}, {{ personalInfo.neighborhood }}
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col-xs-9" *ngIf="orderType === 'HERE' && isValidInformation">
    <mat-list>
      <div mat-subheader>Mi nombre</div>
      <mat-list-item>
        <mat-icon mat-list-icon>face</mat-icon>
        <div mat-line>{{ personalInfo.customerName }}</div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col-xs-9" *ngIf="orderType === 'PICK_UP' && isValidInformation">
    <mat-list>
      <div mat-subheader>Mis datos</div>
      <mat-list-item>
        <mat-icon mat-list-icon>face</mat-icon>
        <div mat-line (click)="openPersonalInfo()">
          {{ personalInfo.customerName }}
        </div>
        <div mat-line (click)="openPersonalInfo()">
          {{ personalInfo.phone }}
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col-xs-9" *ngIf="!isValidInformation">
    <mat-list>
      <div mat-subheader>Mis datos</div>
      <mat-list-item>
        <mat-icon mat-list-icon>face</mat-icon>
        <div mat-line class="add-info" (click)="openPersonalInfo()">
          <span>Por favor agrega tus datos de contacto</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col-xs-3 change">
    <span (click)="openPersonalInfo()">{{
      isValidInformation ? "Cambiar" : "Agregar"
    }}</span>
  </div>
</div>
