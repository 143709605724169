<div class="row product-title-stick">
  <mat-icon class="product-arrow-back" color="primary" (click)="dismiss()"
    >arrow_back</mat-icon
  >
</div>
<div *ngIf="loading">
  <app-loading message="Cargando..."></app-loading>
</div>

<div class="content-fluid" *ngIf="!loading">
  <div class="row">
    <img [src]="product.urlImage" />
  </div>

  <div>
    <div class="row">
      <div class="col-xs-12">
        <div class="box">
          <h2>{{ product.name }}</h2>
        </div>
      </div>
      <app-product-label-discount
        [product]="product"
        [isDetail]="true"
      ></app-product-label-discount>
      <div class="col-xs-12">
        <div class="box">
          <p>{{ product.description }}</p>
        </div>
      </div>
    </div>
    <form
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()"
      *ngIf="formGroup && extras"
    >
      <div class="col-xs-12" *ngFor="let extra of extras | sortExtras : sort">
        <div class="box">
          <div class="row" *ngIf="extra.type === 'UNIQUE'">
            <div class="col-xs-12 extras--name">
              <span>{{ extra.name }}</span>
              <span class="required" *ngIf="!extra.optional">Requerido</span>
            </div>
            <div class="col-xs-12">
              <mat-radio-group
                [id]="extra.extraId"
                formControlName="{{ extra.extraId }}"
                aria-label="Select an option"
              >
                <div
                  class="options option"
                  *ngFor="
                    let option of extra.options | sortOptions : extra.sort
                  "
                >
                  <span class="option--name">{{ option.name }}</span>
                  <mat-radio-button
                    color="primary"
                    [value]="option"
                    labelPosition="before"
                  >
                    <span *ngIf="option.price > 0" class="option--price">{{
                      option.price | currency
                    }}</span>
                    <span *ngIf="option.price < 1" class="option--price"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                    >
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <div class="row" *ngIf="extra.type === 'MULTIPLE'">
            <div class="col-xs-12 extras--name remove-margin">
              <span>{{ extra.name }}</span>
              <span class="required" *ngIf="!extra.optional">Requerido</span>
            </div>
            <div class="col-xs-12 choose-up-to">
              <span>Elige hasta {{ extra.max }}</span>
            </div>
            <div class="col-xs-12">
              <div
                class="options option"
                *ngFor="let option of extra.options | sortOptions : extra.sort"
              >
                <span class="option--name">{{ option.name }}</span>
                <mat-checkbox
                  formControlName="{{ extra.extraId }}_{{ option.optionId }}"
                  (change)="
                    validateMaxOptions($event, extra.extraId, option.optionId)
                  "
                  color="primary"
                  labelPosition="before"
                >
                  <span *ngIf="option.price > 0" class="option--price">{{
                    option.price | currency
                  }}</span>
                  <span *ngIf="option.price < 1" class="option--price"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="extra.type === 'OPEN'">
            <div class="col-xs-12 extras--name">
              <span>{{ extra.name }}</span>
            </div>
            <div class="col-xs-12">
              <div class="box">
                <mat-form-field appearance="outline">
                  <mat-label>Escribir...</mat-label>
                  <textarea
                    matInput
                    [id]="extra.extraId"
                    formControlName="{{ extra.extraId }}"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </form>
  </div>
  <div class="no--extras" *ngIf="!formGroup || !extras">&nbsp;</div>
  <div class="row">
    <div class="col-xs-12">
      <div class="box">
        <div class="section-buttons" *ngIf="businessIsOpen">
          <button
            mat-mini-fab
            aria-label="Remove"
            *ngIf="quantity <= 1"
            disabled="true"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <button
            mat-mini-fab
            aria-label="Remove"
            (click)="addQuantity(-1)"
            *ngIf="quantity > 1"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <span class="quantity-text">{{ quantity | number }}</span>
          <button mat-mini-fab aria-label="Add" (click)="addQuantity(1)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row footer">
    <div class="col-xs-12">
      <div class="box">
        <button
          mat-raised-button
          *ngIf="businessIsOpen"
          [disabled]="!formValid"
          (click)="onSubmit()"
        >
          <div class="button__add">
            <span class="button__add--text"
              >Agregar<span *ngIf="quantity > 0"
                >&nbsp;&nbsp;&nbsp;{{ quantity | number }}</span
              ></span
            >
            <span class="button__add--text">•</span>
            <span class="button__add--text">{{ total | currency }}</span>
          </div>
        </button>
        <button mat-raised-button *ngIf="!businessIsOpen" disabled="true">
          <div class="button__add">
            <span class="button__add--text">Negocio cerrado</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
