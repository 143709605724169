import { Injectable } from '@angular/core';
import { Keys } from '@core/helpers/keys.helper';
import { toOrder, toOrderRequest } from '@core/mappers/order.mapper';
import { CustomerInfo } from '@core/models/customer-info.model';
import { OrderRequest } from '@core/models/order-request.model';
import { OrderRequested } from '@core/models/order-requested.model';
import { PersonalInfo } from '@core/models/personal-info.model';
import { CallApiService, LocalDataService } from '@core/services';
import { SecurityService } from '@core/services/security.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CartService } from './cart.service';
import { v4 as uuidv4 } from 'uuid';
import {
  AddProductDto,
  OrderDto,
  ProductDto,
  RemoveProductDto,
  SaveOrderDto,
  UpdateProductDto,
} from '../dtos';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private _SEGMENT: string = 'orders';
  private _KEY: string = '__co';
  private _KEY_ORDERS_REQUESTED = '__ordersRequested';
  private _KEY_LAST_ORDER = '__lastOrder';
  private _KEY_ORDER_WHATSAPP = '__orderWhatsapp';

  constructor(
    private ldsvc: LocalDataService,
    private casvc: CallApiService,
    private securitySvc: SecurityService,
    private cartSvc: CartService
  ) {}

  createOrder() {
    const order: OrderDto = {} as OrderDto;
    this.setCurrentOrder(order);
  }

  create(): Observable<OrderDto> {
    const order = <SaveOrderDto>{
      orderId: uuidv4(),
      status: 'CREATED',
    };
    const query = `
            mutation ($order: SaveOrderInput!){
              saveOrder(order: $order) {
                  orderId
                  status
                  createdIn
              }
            }`;
    const variables = { order };
    return this.casvc
      .query<OrderDto>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables,
      })
      .pipe(
        tap((response: any) => this.setCurrentOrder(response.saveOrder)),
        map((response: any) => response.saveOrder),
        catchError((error) => throwError(error))
      );
  }

  addProduct({
    addProduct,
  }: {
    addProduct: AddProductDto;
  }): Observable<OrderDto> {
    const query = `
            mutation ($order: SaveOrderInput!){
              saveOrder(order: $order) {
                  orderId
                  status
                  lastUpdate
              }
            }`;
    addProduct.order.product.productOrderId = uuidv4();
    const request = JSON.parse(JSON.stringify(addProduct));
    delete request.order.product.promotion;
    return this.casvc
      .query<OrderDto>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables: request,
      })
      .pipe(
        tap((response: any) => {
          let products: Map<string, ProductDto> = this.cartSvc.getProducts();
          if (!products) {
            products = new Map<string, ProductDto>();
          }
          const productDto = addProduct.order.product;
          products.set(productDto.productOrderId, productDto);
          this.cartSvc.setProducts(products);
          this.setCurrentOrder(response.saveOrder);
        }),
        map((order: OrderDto) => order),
        catchError((error) => throwError(error))
      );
  }

  updateProduct({
    updateProduct,
  }: {
    updateProduct: UpdateProductDto;
  }): Observable<OrderDto> {
    const query = `
            mutation ($order: SaveOrderInput!){
              saveOrder(order: $order) {
                  orderId
                  status
                  lastUpdate
              }
            }`;
    const request = JSON.parse(JSON.stringify(updateProduct));
    delete request.order.product.promotion;
    return this.casvc
      .query<OrderDto>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables: request,
      })
      .pipe(
        tap((response: any) => this.setCurrentOrder(response.saveOrder)),
        catchError((error) => throwError(error))
      );
  }

  removeProduct({
    removeProduct,
  }: {
    removeProduct: RemoveProductDto;
  }): Observable<OrderDto> {
    const query = `
            mutation ($order: SaveOrderInput!){
              saveOrder(order: $order) {
                  orderId
                  status
                  lastUpdate
              }
            }`;
    const request = JSON.parse(JSON.stringify(removeProduct));
    delete request.order.product.promotion;
    return this.casvc
      .query<OrderDto>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables: request,
      })
      .pipe(
        tap((response: any) => this.setCurrentOrder(response.saveOrder)),
        catchError((error) => throwError(error))
      );
  }

  confirm(): Observable<OrderDto> {
    const currentOrder: OrderDto = this.getCurrentOrder();
    if (
      currentOrder.status !== 'ADDED_PRODUCT' &&
      currentOrder.status !== 'REMOVED_PRODUCT' &&
      currentOrder.status !== 'UPDATED_PRODUCT'
    ) {
      return of(<OrderDto>{});
    }
    const order = <SaveOrderDto>{
      orderId: currentOrder.orderId,
      status: 'CONFIRMED',
    };
    const query = `
            mutation ($order: SaveOrderInput!){
              saveOrder(order: $order) {
                orderId
                status
                lastUpdate
                products {
                  productOrderId
                  categoryId
                  productId
                  name
                  price
                  quantity
                  extras {
                    extraId
                    name
                    type
                    value
                    options {
                      optionId
                      name
                      price
                    }
                  }
                }
              }
            }`;
    const variables = { order };
    return this.casvc
      .query<OrderDto>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables,
      })
      .pipe(
        tap((response: any) => this.setCurrentOrder(response.saveOrder)),
        map((response: any) => response.saveOrder),
        catchError((error) => throwError(error))
      );
  }

  request(order: OrderDto, personalInfo?: PersonalInfo): Observable<OrderDto> {
    let orderRequest: OrderRequest = toOrderRequest(order, personalInfo);
    const query = `
    mutation ($order: SaveOrderInput!){
      saveOrder(order: $order) {
        orderId
        status
        lastUpdate
      }
    }`;
    const variables = { order: orderRequest };
    return this.casvc
      .query<OrderDto>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables,
      })
      .pipe(
        tap((response: any) => this.setCurrentOrder(response.saveOrder)),
        tap((_) => this.clearCartAndOrder(orderRequest, order)),
        map((response: any) => response.saveOrder),
        catchError((error) => throwError(error))
      );
  }

  confirmFromPointOfSale(): Observable<OrderDto> {
    return this.confirm().pipe(
      switchMap(() => {
        const order: OrderDto = this.getCurrentOrder();
        order.type = 'HERE';
        order.paymentType = 'ON_SITE';
        return this.request(order).pipe(map((response) => response));
      })
    );
  }

  getOrderFromCart(): OrderDto {
    const businessId = this.securitySvc.getDataToken(
      SecurityService.BUSINESS_TOKEN_POSITION
    );
    const productsMap: Map<string, ProductDto> = this.cartSvc.getProducts();
    const products: ProductDto[] = Array.from(productsMap.values());
    const order: OrderDto = toOrder(products, businessId);
    const prevOrder: OrderDto = this.getCurrentOrder();
    if (prevOrder && prevOrder.orderId) {
      order.orderId = prevOrder.orderId;
    }
    return order;
  }

  getLastOrder(): OrderRequest {
    return this.ldsvc.get(this._KEY_LAST_ORDER);
  }

  setCurrentOrder(order: OrderDto) {
    this.ldsvc.set(this._KEY, order);
  }

  clearCartAndOrder(orderRequest: OrderRequest, order: OrderDto) {
    const orderRequested = <OrderRequested>{ orderRequest, order };
    orderRequested.createdAt = new Date();
    this.addOrderToOrdersRequested(orderRequested);
    this.setLastOrder(orderRequest);
    this.createOrder();
    this.cartSvc.clearCart();
  }

  setLastOrder(order: OrderRequest) {
    this.ldsvc.set(this._KEY_LAST_ORDER, order);
  }

  getCurrentOrder(): OrderDto {
    return this.ldsvc.get(this._KEY);
  }

  setPersonalInfo(personalInfo: PersonalInfo) {
    this.ldsvc.set(Keys.PERSONAL_INFO, personalInfo);
  }

  setWhatsappOrder(value: boolean) {
    this.ldsvc.set(this._KEY_ORDER_WHATSAPP, value);
  }

  getWhatsappOrder(): boolean {
    return this.ldsvc.get(this._KEY_ORDER_WHATSAPP);
  }

  getPersonalInfo(): PersonalInfo {
    const value = this.ldsvc.get(Keys.PERSONAL_INFO);
    if (!value) return null;
    return <PersonalInfo>value;
  }

  getOrdersRequested(): Map<string, OrderRequested> {
    const value = this.ldsvc.get(this._KEY_ORDERS_REQUESTED);
    if (!value) return null;
    return new Map<string, OrderRequested>(Object.entries(value));
  }

  addOrderToOrdersRequested(order: OrderRequested) {
    let orders: Map<string, OrderRequested> = this.getOrdersRequested();
    if (!orders) {
      orders = new Map<string, OrderRequested>();
    }
    orders.set(order.orderRequest.orderId, order);
    this.ldsvc.set(this._KEY_ORDERS_REQUESTED, Object.fromEntries(orders));
  }

  addCustomerInfoToPersonalInfo(customer: CustomerInfo) {
    let personalInfo = this.getPersonalInfo();
    personalInfo = {
      ...personalInfo,
      birthday: customer.birthday,
      customerName: customer.name,
      phone: customer.phone,
      email: customer.email ?? personalInfo?.email,
    };
    this.setPersonalInfo(personalInfo);
  }
}
