import { Component, Input, OnInit } from '@angular/core';
import { ProductHelper } from '@client/core/helpers/product.helper';
import { Product } from '@core/models/product.model';
import { Promotion } from '@core/models/promotion.model';

@Component({
  selector: 'app-product-label-discount',
  templateUrl: './product-label-discount.component.html',
  styleUrls: ['./product-label-discount.component.scss'],
})
export class ProductLabelDiscountComponent implements OnInit {
  @Input() product: Product = {} as Product;
  @Input() isDetail: boolean = false;

  validDiscount: boolean;
  price: number;

  promotion: Promotion;

  constructor() {}

  ngOnInit(): void {
    this.validDiscount = ProductHelper.isValidDiscount(this.product);
    this.price = ProductHelper.getPrice(this.product, this.validDiscount);
    this.promotion = this.product.promotions?.length
      ? this.product.promotions[0]
      : null;
  }
}
