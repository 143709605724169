import { Pipe, PipeTransform } from '@angular/core';
import { ExtraDto } from '@core/dtos';
import { OrderExtra } from '@core/models/order-extra.model';

@Pipe({
  name: 'describeExtras',
})
export class DescribeExtrasPipe implements PipeTransform {
  transform(extras: ExtraDto[]): string {
    if (!extras) return '';

    let text: string = '';
    for (let extra of extras) {
      let value: string = '';
      if (extra.value) {
        text += `• ${extra.value} `;
        continue;
      }
      if (extra.options) {
        for (let option of extra.options) {
          text += `• ${option.name} `;
        }
      }
    }
    return text;
  }
}
