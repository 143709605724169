import { Component, OnInit } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { OrderRequested } from '@core/models/order-requested.model';
import { LocalDataService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { OrderReader } from '@store/client/order/order-reader/order-reader.actions';
import { OrderReaderState } from '@store/client/order/order-reader/order-reader.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent extends CoreComponent {
  @Select(OrderReaderState.get) orders$: Observable<
    Map<string, OrderRequested>
  >;

  orders: OrderRequested[];

  constructor(public store: Store, private localDataSvc: LocalDataService) {
    super();
  }

  onInit(): void {
    this.orders$.pipe(takeUntil(this.unsubscribe$)).subscribe((orders) => {
      if (!orders) return;

      this.orders = Array.from(orders.values());
      this.orders = this.orders.reverse();
    });
    this.store.dispatch(new OrderReader.FromRepository());
  }

  goToMenu() {
    const key: string = this.localDataSvc.get<string>('__ck');
    if (key) {
      window.location.href = `/${key}`;
    }
  }
}
