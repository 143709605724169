import { Component, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CoreComponent } from '@client/core/core.component';
import { Order } from '@core/models/order.model';
import { LocalDataService } from '@core/services';
import { OrderType } from '@core/types/order-type.type';
import { Select, Store } from '@ngxs/store';
import { OrderTypeAction } from '@store/client/order/order-type/order-type.actions';
import { RequestOrderState } from '@store/client/order/request-order/request-order.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateOrderState } from '@store/client/order/create-order/create-order.state';
import { GetMenuState } from '@store/client/menu/states/get-menu.state';
import { GetMenuAction } from '@store/client/menu/actions/get-menu.actions';
import { Menu } from '@core/models/menu.model';
import { ConfirmOrder } from '@store/client/order/confirm-order/confirm-order.actions';
import { ConfirmOrderState } from '@store/client/order/confirm-order/confirm-order.state';
import {
  CartGetAction,
  CartGetTotalAction,
} from '@store/client/cart/actions/cart.actions';
import { BusinessSchedulerState } from '@store/client/business-scheduler/business-scheduler.state';
import { Business } from '@core/models/business.model';
import { BusinessScheduler } from '@store/client/business-scheduler/business-scheduler.actions';
import { OrderWhatsapp } from '@store/client/order/order-whatsapp/order-whatsapp.actions';

@Component({
  selector: 'app-cart-confirm',
  templateUrl: './cart-confirm.component.html',
  styleUrls: ['./cart-confirm.component.scss'],
})
export class CartConfirmComponent extends CoreComponent {
  @Select(RequestOrderState.loading) loading$: Observable<boolean>;
  @Select(RequestOrderState.get) getOrder$: Observable<Order>;
  @Select(RequestOrderState.onlinePayment) onlinePayment$: Observable<string>;
  @Select(CreateOrderState.getMessage) getMessage$: Observable<string>;
  @Select(GetMenuState.getMenu) menu$: Observable<Menu>;
  @Select(ConfirmOrderState.isSuccess) confirmSuccess$: Observable<boolean>;
  @Select(BusinessSchedulerState.getBusiness)
  getBusiness$: Observable<Business>;

  orderType: OrderType;
  loading: boolean = true;
  confirmationLoading = true;
  order: Order;
  menu: Menu;
  showGoToMenu: boolean;
  business: Business;

  constructor(
    private store: Store,
    public activatedRoute: ActivatedRoute,
    private localDataSvc: LocalDataService,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super();
  }

  onInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: Params) => {
        this.orderType = params?.orderType;
        this.store.dispatch(new OrderTypeAction(this.orderType));
        this.store.dispatch(new BusinessScheduler.Get());
      });

    this.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => (this.loading = loading));

    this.getOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((order) => {
      if (!order) return;

      this.router
        .navigate(['order-requested'])
        .then((nav) => {
          console.log('navigated to order-requested');
        })
        .catch((error) => {
          console.error('Error trying to navigate to order-requested', error);
        })
        .finally(() => {
          this.store.dispatch(new OrderWhatsapp.Inital());
        });
      this.order = order;
    });

    this.onlinePayment$.pipe(takeUntil(this.unsubscribe$)).subscribe((url) => {
      if (url == null) {
        return;
      }
      window.location.href = url;
    });

    this.getMessage$.pipe(takeUntil(this.unsubscribe$)).subscribe((message) => {
      if (!message) return;

      this.goToMenu();
    });

    this.menu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu: Menu) => {
      if (!menu) return;

      this.menu = menu;
    });

    this.getBusiness$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business: Business) => {
        if (!business) return;

        this.business = business;
      });

    this.confirmSuccess$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((success: boolean) => {
        if (!success) return;
        this.confirmationLoading = false;
        this.store.dispatch(new CartGetAction());
        this.store.dispatch(new CartGetTotalAction());
      });

    this.store.dispatch(new ConfirmOrder.Confirm());
    if (!this.menu) this.store.dispatch(new GetMenuAction());
  }

  goToMenu() {
    const key: string = this.localDataSvc.get<string>('__ck');
    if (key) {
      window.location.href = `/${key}`;
    }
  }
}
