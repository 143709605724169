import { Injectable } from '@angular/core';
import { Category } from '@core/models/category.model';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private _SEGMENT: string = 'customer/api/categories';
  private _KEY: string = '__c';

  constructor(
    private afsvc: ApiFacadeService,
    private ldsvc: LocalDataService
  ) {}

  get(idCategory: string): Observable<Category> {
    return this.afsvc
      .get<Category>({
        segment: `${this._SEGMENT}/${idCategory}`,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${idCategory}`,
        },
        isOldGateway: false,
      })
      .pipe(map((category) => category));
  }

  getCurrentCategory(): Observable<Category> {
    return this.ldsvc.getObs<Category>('__cc');
  }

  setCurrentCategory(
    category: Category,
    refresh: boolean
  ): Observable<Category> {
    if (!this.ldsvc.containsKey('__cc'))
      this.ldsvc.set<Category>('__cc', category);

    if (refresh) this.ldsvc.set<Category>('__cc', category);

    return of(category);
  }
}
