import { Injectable } from '@angular/core';
import { OrderService } from '@core/https/order.service';
import { PersonalInfo } from '@core/models/personal-info.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  PersonalInfoGetAction,
  PersonalInfoSetAction,
} from './personal-info.actions';

export class PersonalInfoStateModel {
  public personalInfo: PersonalInfo;
}

const defaults = {
  personalInfo: null,
};

@State<PersonalInfoStateModel>({
  name: 'personalInfo',
  defaults,
})
@Injectable()
export class PersonalInfoState {
  constructor(private orderSvc: OrderService) {}

  @Selector()
  public static get({ personalInfo }) {
    return personalInfo;
  }

  @Action(PersonalInfoSetAction)
  set(
    ctx: StateContext<PersonalInfoStateModel>,
    { personalInfo }: PersonalInfoSetAction
  ) {
    this.orderSvc.setPersonalInfo(personalInfo);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      personalInfo: personalInfo,
    });
  }

  @Action(PersonalInfoGetAction)
  get(ctx: StateContext<PersonalInfoStateModel>) {
    const personalInfo = this.orderSvc.getPersonalInfo();
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      personalInfo: personalInfo,
    });
  }
}
