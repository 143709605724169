<div class="categories__container mat-elevation-z6">
    <div class="row">
        <div class="col-xs-12">
            <div class="box" *ngIf="menu">
                <mat-chip-list *ngIf="menu.categories && currentCategory">
                    <mat-chip *ngFor="let category of menu.categories | sortCategories : menu.sort"
                        (click)="setCurrentCategory(category)"
                        [class]="currentCategory.categoryId === category.categoryId ? 'mat-chip-selected' : ''"
                        appCategoryScrollBehavior>
                      {{category.name}}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
</div>
