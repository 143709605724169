import { Injectable } from '@angular/core';
import { CustomerInfo } from '@core/models/customer-info.model';
import { CallApiService, LocalDataService } from '@core/services';
import { OrderService } from './order.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { Keys } from '@core/helpers/keys.helper';
import { SecurityService } from '@core/services/security.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private _SEGMENT: string = 'businesses';

  constructor(
    private casvc: CallApiService,
    private orderService: OrderService,
    private securityService: SecurityService,
    private localDataService: LocalDataService
  ) {}

  registerCustomerToOffers(customer: CustomerInfo): Observable<CustomerInfo> {
    if (!customer.email) {
      delete customer.email;
    }
    if (!customer.birthday) {
      delete customer.birthday;
    }
    this.orderService.addCustomerInfoToPersonalInfo(customer);
    return this.casvc
      .post<CustomerInfo>({
        urlSegment: `${this._SEGMENT}/customers`,
        data: customer,
        isOldGateway: false,
      })
      .pipe(
        tap((_) => this.addSubscribeToLocalStorage(customer)),
        map((_) => customer),
        catchError((error) => throwError(error))
      );
  }

  isBusinessSubscribed(): boolean {
    const businessId = this.securityService.getDataToken(
      SecurityService.BUSINESS_TOKEN_POSITION
    );
    let subscribers = this.localDataService.getMany<string>(Keys.SUBSCRIBED);
    if (subscribers.includes(businessId)) return true;
    return false;
  }

  private addSubscribeToLocalStorage(customer: CustomerInfo) {
    if (!customer?.birthday) return;

    const businessId = this.securityService.getDataToken(
      SecurityService.BUSINESS_TOKEN_POSITION
    );
    let subscribers = this.localDataService.getMany<string>(Keys.SUBSCRIBED);
    if (subscribers.includes(businessId)) {
      return;
    }
    let setBusinesss: Set<string> = new Set<string>();
    if (subscribers.length) {
      subscribers.forEach((v) => setBusinesss.add(v));
    }
    setBusinesss.add(businessId);
    this.localDataService.setMany(Keys.SUBSCRIBED, Array.from(setBusinesss));
  }
}
