import { Injectable } from '@angular/core';
import { Menu } from '@core/models/menu.model';
import { SecurityService } from '@core/services/security.service';
import { CallApiService, LocalDataService } from '@core/services';
import { iif, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _SEGMENT: string = 'menus';
  private _KEY: string = '__m';

  constructor(
    private securitySvc: SecurityService,
    private casvc: CallApiService,
    private ldsvc: LocalDataService,
    private orderSvc: OrderService
  ) {}

  get(idMenu?: string): Observable<Menu> {
    if (idMenu) return this.getFromApi(idMenu);
    return this.getFromLocal();
  }

  private getFromApi(idMenu: string): Observable<Menu> {
    return this.casvc
      .get<Menu>({
        urlSegment: `${this._SEGMENT}`,
        isOldGateway: false,
      })
      .pipe(
        switchMap((menu: Menu) =>
          iif(
            () => !menu,
            this.getFromLocal(),
            of(menu).pipe(
              tap((menu: Menu) => {
                this.securitySvc.refreshToken(menu.version);
                this.ldsvc.set(`${this._KEY}${idMenu}`, menu);
                this.orderSvc.createOrder();
              })
            )
          )
        )
      );
  }

  private getFromLocal(): Observable<Menu> {
    const menuId = this.securitySvc.getDataToken(
      SecurityService.MENU_TOKEN_POSITION
    );
    return this.ldsvc.getObs<Menu>(`__m${menuId}`);
  }
}
