<h2 mat-dialog-title>Pólitica de privacidad</h2>
<mat-dialog-content class="mat-typography">
  <p>
    En NotMenu, la información y datos personales de los beneficiarios,
    colaboradores, donantes, asociados, empleados y proveedores, es tratada de
    forma estrictamente confidencial y con la secrecía necesaria para lograr los
    fines de la organización, por lo que hacemos un esfuerzo permanente para
    salvaguardarla, de conformidad con los artículos 8, 15, 16 y 36 de la Ley
    Federal de Protección de Datos Personales en Posesión de los Particulares.
  </p>

  <h4><b>Identidad y domicilio de la organización</b></h4>
  ​
  <p>
    NotMenu, es una asociación civil sin fines de lucro, enfocada a diseñar
    soluciones tecnológicas. En concordancia con el derecho a la protección de
    datos personales en posesión de particulares consagrado en el artículo 6° de
    la Constitución Política de los Estados Unidos Mexicanos, así como en
    cumplimiento a las disposiciones de la Ley Federal de Protección de Datos
    Personales en Posesión de los Particulares, y en base a los principios de
    licitud, consentimiento, información, calidad, finalidad, lealtad,
    proporcionalidad y responsabilidad; NotMenu, con domicilio en Torre Blanca,
    CDMX, es responsable de recabar y proteger los datos personales, así como
    del uso que se le dé a los mismos. ​ Si el titular de los datos personales
    no acepta en forma absoluta y completa los términos y condiciones de este
    aviso, deberá abstenerse de compartir cualquier tipo de información a la
    NotMenu por cualquier medio físico, óptico y/o electrónico.
  </p>
  <h4><b>Propósito para la recolección y uso de los datos</b></h4>
  <p>
    Los datos personales son utilizados para las siguientes finalidades: A) En
    la comunicación con terceros y donantes nacionales e internacionales, con
    fines estadísticos, administrativos y de recaudación de fondos para apoyar
    la atención de los beneficiarios. B) Para el registro de los beneficios y
    obligaciones establecidas por las leyes mexicanas, así como para fines
    administrativos, de desarrollo y estadísticos, relacionados con nuestros
    trabajadores. C) Los datos personales de los donantes, personas físicas y
    morales, nacionales e internacionales, son utilizados de manera interna para
    fines estadísticos, administrativos y de recaudación de fondos; y de manera
    externa para trasparentar el origen y destino de los recursos operados por
    NotMenu. D) Adicionalmente a lo anterior, los datos personales de los
    diferentes grupos de interés de la NotMenu serán tratados en general para
    mantener una fluida comunicación con ellos a través del envió de
    información, invitaciones y felicitaciones, y llevar a cabo trámites
    administrativos.
  </p>
  <h4><b>Datos personales recabados</b></h4>
  <p>
    Para los fines antes citados NotMenu, requiere obtener los siguientes datos
    personales: ​ A) De las personas físicas Nombre completo. Dirección.
    Teléfono local y/o móvil. Correo electrónico. Fecha de nacimiento. Lugar de
    nacimiento. Estado Civil. Escolaridad. Ocupación laboral. Sexo. Información
    financiera en los casos que proceda. Otro tipo de información específica, de
    acuerdo a las necesidades de las diferentes áreas y conforme a los fines de
    la organización. ​ B) De las personas morales Nombre de la institución.
    Registro Federal de Contribuyentes. Nombre de los representantes. Dirección.
    Teléfono. Correo electrónico. Otro tipo de información específica, de
    acuerdo a las necesidades de las diferentes áreas y conforme a los fines de
    la organización.
  </p>
  <h4><b>Manejo de datos personales sensible</b></h4>
  <p>
    Algunos de los datos recabados que se mencionan en los incisos antes
    citados, son considerados como datos personales sensibles, por lo que el uso
    y tratamiento de los mismos por parte de la organización, se hace con el más
    estricto control, confidencialidad y secrecía necesarios para la
    proveeduría, protección y bienestar de nuestra población de interés, dicho
    manejo de los datos personales y datos personales sensibles es necesario
    para lograr las finalidades legítimas, concretas y acordes de NotMenu. ​
  </p>
  <h4>
    <b>Opciones y medios para limitar el uso o divulgación de los datos</b>
  </h4>
  <p>
    En todo momento el titular podrá limitar el uso o divulgación del
    tratamiento de sus datos personales, para tal efecto deberá ponerse en
    contacto a través del correo electrónico notmenu.binaryfractal@gmail.com y
    será necesario que presente su petición al responsable del manejo de la
    información de la organización cuyos datos de contacto vienen a
    continuación. Medios para ejercer los derechos de acceso a los datos,
    rectificación, cancelación u oposición de los mismos, de conformidad con lo
    dispuesto por la Ley El titular tiene derecho de acceder a sus datos
    personales que NotMenu posee y a los detalles del tratamiento de los mismos,
    rectificar en caso de ser inexactos o incompletos, cancelarlos cuando
    considere que no se requieren para alguna de las finalidades señaladas en el
    aviso de privacidad, así como a oponerse al tratamiento de los mismos y/o
    revocar en los casos que proceda, cuando la ley específica lo permita, el
    consentimiento que para tal fin nos haya otorgado, a través de los
    procedimientos que se han implementado para tal efecto. Para conocer más
    detalles sobre el procedimiento respectivo, requisitos y plazos, puede
    contactar a René Santiago Reséndiz, responsable de manejo de la información,
    al siguiente correo electrónico notmenu.binaryfractal@gmail.com
  </p>
  <h4>
    <b
      >Procedimiento a seguir en caso de que el titular de los datos cambie de
      opinión posteriormente y decida revocar su consentimiento.</b
    >
  </h4>
  <p>
    La petición debe de ir acompañada de la siguiente información: Nombre
    completo de la persona que desea revocar el consentimiento y documento de
    identidad que acredite la personalidad o documento que acredite la
    representación legal en su caso. Descripción clara y precisa de los datos
    personales respecto de los que se busca ejercer alguno de los derechos antes
    mencionados. Domicilio, correo electrónico u otro medio para comunicarle la
    respuesta a su solicitud. Cualquier otro documento que facilite la
    localización de los datos personales. NotMenu tendrá un plazo de 20 días
    contados, a partir de la fecha en que se recibió la solicitud, para atender
    y comunicarle al titular la determinación adoptada, a efecto de que, si
    resulta procedente, se haga efectiva la misma dentro de los 15 días
    siguientes a la fecha en que se comunica la respuesta. ​ Tratándose de
    solicitudes de acceso de datos personales, procederá la entrega previa
    acreditación de la identidad del solicitante o representante legal según
    corresponda. Los plazos antes referidos podrán ser ampliados una sola vez
    por un periodo igual, siempre y cuando así lo justifiquen las circunstancias
    del caso.
  </p>
  <h4><b>Transferencia de datos</b></h4>
  <p>
    Para lograr el objeto y fines que persigue la organización, los datos
    personales pueden ser transferidos y tratados dentro y fuera del país. La
    información será compartida para fines estadísticos y administrativos. Si el
    titular de los datos personales no manifiesta oposición alguna y no lo
    informa al responsable mediante el procedimiento establecido anteriormente
    citado, se entenderá que ha otorgado su consentimiento para ello. No
    obstante lo anterior, NotMenu se compromete a que la información que se
    transfiere a terceros y que se encuentra prevista en las excepciones que
    fija la ley de la materia para tal efecto, sea tratada de forma responsable,
    confidencial y exclusivamente por aquellas personas que requieren del
    conocimiento de dichos datos.
  </p>
  <h4>
    <b
      >Procedimiento y medios por el cual se comunicará a los titulares de
      cambios al aviso de privacidad</b
    >
  </h4>
  <p>
    Atendiendo las directrices de la autoridad competente, en su caso, de ser
    necesario NotMenu realizará cambios o modificaciones al aviso de privacidad,
    atendiendo también los fines de la organización. En su caso estas
    modificaciones estarán disponibles en la página web institucional o se harán
    llegar a la brevedad al último correo electrónico que los titulares nos
    hayan proporcionado. ​ Este documento atiende las disposiciones de la ley
    aplicable, para casos específicos y en atención al objeto social y fines de
    la organización, se atenderá la situación concreta y se le pondrá a la vista
    el aviso de privacidad simplificado cuyo contenido actualiza la información
    requerida en las fracciones I y II del artículo 16 de la ley de la materia y
    remite al presente aviso de privacidad completo. Cualquier modificación a
    este aviso de privacidad se podrá consultar en la página www.notmenu.com. Si
    alguna persona (física o moral) considera que su derecho de protección de
    datos personales ha sido lesionado por alguna conducta de los colaboradores
    de NotMenu o de las actuaciones de la organización, presume que en el
    tratamiento de sus datos personales existe alguna violación a las
    disposiciones previstas en le Ley Federal de Protección de Datos Personales
    en Posesión de Particulares, podrá interponer la queja o denuncia
    correspondiente ante el IFAI.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
