import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartConfirmComponent } from '@client/pages/cart-confirm/cart-confirm.component';
import { OrderRequestedComponent } from '@client/pages/orders/order-requested/order-requested.component';
import { CancelCheckoutComponent } from '@client/pages/payment/cancel-checkout/cancel-checkout.component';
import { CheckoutComponent } from '@client/pages/payment/checkout/checkout.component';

// Pages
import { NotFoundComponent } from '@shared/pages/not-found/not-found.component';

const routes: Routes = [
  { path: '', component: NotFoundComponent },
  { path: '', redirectTo: '/404', pathMatch: 'full' },
  { path: 'cart-confirm/:orderType', component: CartConfirmComponent },
  { path: 'order/:orderType', component: CartConfirmComponent },
  { path: 'order-requested', component: OrderRequestedComponent },
  { path: 'payment/checkout', component: CheckoutComponent },
  { path: 'payment/checkout/cancel', component: CancelCheckoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
