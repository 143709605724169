import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '@core/types/payment.type';

@Pipe({
  name: 'paymentTypeIcon',
})
export class PaymentTypeIconPipe implements PipeTransform {
  transform(paymentType: PaymentType): string {
    return paymentType === 'ON_SITE' ? 'storefront' : 'credit_card';
  }
}
