import { Product } from '@core/models/product.model';

export class GetProductAction {
  static readonly type = '[GetProduct]';
  constructor(public productId: string) {}
}

export class GetProductSuccessAction {
  static readonly type = '[GetProduct] Success';
  constructor(public product: Product) {}
}

export class GetProductFailedAction {
  static readonly type = '[GetProduct] Failed';
  constructor() {}
}
