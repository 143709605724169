<div class="header"></div>
<div class="row" *ngIf="currentCategory">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
        <div class="box">
            <app-product *ngFor="let product of currentCategory.products | sortProducts: currentCategory.sort "
                [product]="product">
            </app-product>
        </div>
    </div>
</div>
<div class="footer"></div>
