import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCategoryScrollBehavior]'
})
export class CategoryScrollBehaviorDirective {
  @Input() el!: Element;

  constructor() { }

  @HostListener('click', ['$event']) 
  scrollIntoView(event: any) {
    event.target.scrollIntoView({ block: "end", inline: "center", behavior: "smooth" });
  }

}
