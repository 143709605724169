<div class="row center-xs">
  <div class="col-xs-12">
    <h3>Suscríbete a nuestras promociones</h3>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 registry-text">
    <span
      >Registrate para recibir las mejores promociones y comienza a ahorrar de
      inmediato.</span
    >
  </div>
</div>
<div class="row center-xs">
  <div class="col-xs-12">
    <form #mainForm [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Nombre *</mat-label>
            <mat-icon matSuffix>badge_outline</mat-icon>
            <input
              matInput
              type="text"
              formControlName="name"
              autocomplete="name"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-form-field
            class="form__field"
            appearance="outline"
            (click)="birthday.open()"
          >
            <mat-label>Cumpleaños *</mat-label>
            <input
              matInput
              [matDatepicker]="birthday"
              formControlName="birthday"
              readonly
              autocomplete="off"
              [max]="today"
            />
            <mat-icon matSuffix>edit_calendar_outline</mat-icon>
            <mat-datepicker #birthday disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>WhatsApp *</mat-label>
            <input
              matInput
              type="number"
              #inputPhone
              formControlName="phone"
              onKeyPress="if(this.value.length===10) return false;"
              autocomplete="off"
            />
            <mat-icon matSuffix>chat_bubble_outline</mat-icon>
            <mat-hint align="end"
              >{{ inputPhone.value?.length || 0 }}/10</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Email (opcional)</mat-label>
            <mat-icon matSuffix>alternate_email_outline</mat-icon>
            <input
              matInput
              type="email"
              formControlName="email"
              autocomplete="email"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 start-xs">
          <span class="privacity"
            >Al hacer clic en "Registrarte", aceptas nuestras Condiciones y la
            <span (click)="openPrivacyPolicy()">Política de privacidad</span> de
            NotMenu.</span
          >
        </div>
        <div class="col-xs-12">
          <button
            mat-raised-button
            type="submit"
            [disabled]="!formGroup.valid"
            class="register-button"
          >
            Registrarte
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div>&nbsp;</div>
