import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { CoreComponent } from '@client/core/core.component';
import { CustomerInfo } from '@core/models/customer-info.model';
import { PersonalInfo } from '@core/models/personal-info.model';
import { OrderType } from '@core/types/order-type.type';
import { Store } from '@ngxs/store';
import { PrivacyPolicyDialogComponent } from '@shared/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { CustomerAddAction } from '@store/client/customer/customer.actions';
import { PersonalInfoSetAction } from '@store/client/personal-info/personal-info.actions';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent extends CoreComponent {
  personalInfo: PersonalInfo;
  formGroup: FormGroup;

  private orderType: OrderType;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { personalInfo: PersonalInfo },
    private bottomsheet: MatBottomSheetRef<PersonalInfoComponent>,
    private store: Store,
    public privacyPolicyDialog: MatDialog
  ) {
    super();
  }

  onInit(): void {
    this.personalInfo = this.data.personalInfo;
    this.orderType = this.personalInfo.orderType;

    this.formGroup = new FormGroup({
      customerName: new FormControl(this.personalInfo?.customerName, [
        Validators.required,
        Validators.minLength(3),
      ]),
      neighborhood:
        this.orderType === 'DELIVERY'
          ? new FormControl(this.personalInfo?.neighborhood, [
              Validators.required,
              Validators.minLength(3),
            ])
          : new FormControl(),
      phone:
        this.orderType === 'DELIVERY' || this.orderType === 'PICK_UP'
          ? new FormControl(this.personalInfo?.phone, [
              Validators.required,
              Validators.min(1000000000),
              Validators.max(9999999999),
            ])
          : new FormControl(),
      reference: new FormControl(this.personalInfo?.reference),
      street:
        this.orderType === 'DELIVERY'
          ? new FormControl(this.personalInfo?.street, [
              Validators.required,
              Validators.minLength(3),
            ])
          : new FormControl(),
      zip:
        this.orderType === 'DELIVERY'
          ? new FormControl(this.personalInfo?.zip, [
              Validators.required,
              Validators.minLength(3),
            ])
          : new FormControl(),
    });
  }

  onSubmit() {
    switch (this.orderType) {
      case 'HERE':
        this.personalInfo = {
          ...this.personalInfo,
          customerName: this.formGroup.value.customerName,
        };
        break;
      case 'PICK_UP':
        this.personalInfo = {
          ...this.personalInfo,
          customerName: this.formGroup.value.customerName,
          phone: this.formGroup.value.phone,
        };
        break;
      default:
        this.personalInfo = { ...this.personalInfo, ...this.formGroup.value };
        this.personalInfo.zip = this.personalInfo.zip.toString();
        break;
    }
    this.store.dispatch(new PersonalInfoSetAction(this.personalInfo));
    const customer: CustomerInfo = {
      ...this.personalInfo,
      name: this.personalInfo?.customerName,
      email: this.personalInfo?.email ? this.personalInfo.email : undefined,
      phone: this.personalInfo.phone + '',
    };
    this.store.dispatch(new CustomerAddAction(customer));
    this.bottomsheet.dismiss();
  }

  openPrivacyPolicy() {
    this.privacyPolicyDialog.open(PrivacyPolicyDialogComponent);
  }
}
