import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Product } from '@core/models/product.model';
import { Store } from '@ngxs/store';
import { CartGetTotalAction } from '@store/client/cart/actions/cart.actions';
import { ProductBottomSheetComponent } from '../product-bottom-sheet/product-bottom-sheet.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input() product: Product = {} as Product;

  validDiscount: boolean;

  constructor(
    private productBottomSheet: MatBottomSheet,
    private store: Store
  ) {}

  ngOnInit(): void {}

  openProduct(productId: string): void {
    const productBottomSheetRef = this.productBottomSheet.open(
      ProductBottomSheetComponent,
      {
        hasBackdrop: true,
        data: { productId: productId },
        panelClass: 'product-bottom-sheet-container',
      }
    );

    productBottomSheetRef.afterDismissed().subscribe(() => {
      this.store.dispatch(new CartGetTotalAction());
    });
  }
}
