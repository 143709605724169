import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@core/models/product.model';

@Pipe({
  name: 'sortProducts',
})
export class SortProductsPipe implements PipeTransform {
  transform(products: Product[], sort: string[]): Product[] {
    const sortedProducts: Product[] = [];
    for (const o of sort) {
      if (products.some((p) => p.productId === o)) {
        const product: Product = products.find((p) => p.productId === o);
        sortedProducts.push(product);
      }
    }

    return sortedProducts;
  }
}
