import { Component, Input } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';

@Component({
  selector: 'app-order-qr',
  templateUrl: './order-qr.component.html',
  styleUrls: ['./order-qr.component.scss'],
})
export class OrderQrComponent extends CoreComponent {
  @Input() id: string;

  constructor() {
    super();
  }

  onInit(): void {}
}
