<div class="content-fluid">
  <div class="row--not-margin">
    <div class="col-xs-12">
      <div class="box">
        <mat-icon color="warn">error</mat-icon>
        <div class="error__message">{{ message }}</div>
        <div class="error__button">
          <button mat-raised-button color="warn" (click)="onReload()">
            Recargar página
          </button>
        </div>
        <div class="error__contact" *ngIf="showEmail">
          <a href="mailto:support@notmenu.com">Contactanos</a>
        </div>
      </div>
    </div>
  </div>
</div>
