import { Injectable } from '@angular/core';
import { CategoryService } from '@core/https/category.service';
import { Category } from '@core/models/category.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  CurrentCategorySetAction,
  CurrentCategoryFailedAction,
  CurrentCategorySuccessAction,
  CurrentCategoryGetAction,
} from '../actions/current-category.actions';

export class CurrentCategoryStateModel {
  public category: Category;
}

const defaults = {
  category: null,
};

@State<CurrentCategoryStateModel>({
  name: 'currentCategory',
  defaults,
})
@Injectable()
export class CurrentCategoryState {
  constructor(private categorySvc: CategoryService) {}

  @Selector()
  public static getCurrentCategory({ category }) {
    return category;
  }

  @Action(CurrentCategorySetAction)
  set(
    ctx: StateContext<CurrentCategoryStateModel>,
    { category, refresh }: CurrentCategorySetAction
  ) {
    return this.categorySvc.setCurrentCategory(category, refresh).pipe(
      tap((_category) => {
        ctx.dispatch(new CurrentCategorySuccessAction(_category));
      }),
      catchError(() => {
        ctx.dispatch(new CurrentCategoryFailedAction());
        return of();
      })
    );
  }

  @Action(CurrentCategoryFailedAction)
  fail(ctx: StateContext<CurrentCategoryStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      category: null,
    });
  }

  @Action(CurrentCategoryGetAction)
  get(ctx: StateContext<CurrentCategoryStateModel>) {
    return this.categorySvc.getCurrentCategory().pipe(
      tap((_category) => {
        ctx.dispatch(new CurrentCategorySuccessAction(_category));
      }),
      catchError(() => {
        ctx.dispatch(new CurrentCategoryFailedAction());
        return of();
      })
    );
  }

  @Action(CurrentCategorySuccessAction)
  success(
    ctx: StateContext<CurrentCategoryStateModel>,
    { category }: CurrentCategorySuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      category: category,
    });
  }
}
