import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentTypeSelectorComponent } from '../../payment-type-selector/payment-type-selector.component';
import { PaymentTypeState } from '@store/client/order/payment-type/payment-type.state';
import { PaymentType } from '@core/types/payment.type';
import { BusinessSchedulerState } from '@store/client/business-scheduler/business-scheduler.state';
import { Business } from '@core/models/business.model';
import { PaymentTypeActions } from '@store/client/order/payment-type/payment-type.actions';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss'],
})
export class PaymentTypeComponent extends CoreComponent {
  @Select(BusinessSchedulerState.getBusiness)
  getBusiness$: Observable<Business>;
  @Select(PaymentTypeState.getPaymentType)
  getPaymentType$: Observable<PaymentType>;

  paymentType: PaymentType;
  business: Business;
  availablePaymentTypes: PaymentType[] = ['ON_SITE'];

  constructor(
    private paymentTypeSelectorBottomSheet: MatBottomSheet,
    private store: Store
  ) {
    super();
  }

  onInit(): void {
    this.getBusiness$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business: Business) => {
        if (!business) return;

        this.business = business;
        if (this.business.allowPayments && this.business.stripeUserId) {
          this.availablePaymentTypes.push('ONLINE');
        } else {
          this.store.dispatch(new PaymentTypeActions.Set('ON_SITE'));
        }
      });

    this.getPaymentType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((paymentType: PaymentType) => {
        if (!paymentType) return;

        this.paymentType = paymentType;
      });
  }

  openPaymentTypeSelector(): void {
    this.paymentTypeSelectorBottomSheet.open(PaymentTypeSelectorComponent, {
      hasBackdrop: true,
      panelClass: 'bottom-sheet-no-padding',
    });
  }
}
