<section *ngIf="!business">
  <p>Cargando métodos de pago</p>
</section>
<section *ngIf="business">
  <div>
    <mat-list>
      <div mat-subheader>Métodos de pago</div>
      <mat-list-item
        *ngFor="let paymentType of availablePaymentTypes"
        (click)="setPaymentType(paymentType)"
      >
        <mat-icon mat-list-icon>{{ paymentType | paymentTypeIcon }}</mat-icon>
        <div mat-line>{{ paymentType | paymentType }}</div>
      </mat-list-item>
    </mat-list>
  </div>
</section>
