import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { ProductHelper } from '@client/core/helpers/product.helper';
import { Extra } from '@core/models/extra.model';
import { Option } from '@core/models/option.model';
import { Product } from '@core/models/product.model';
import { OrderProduct } from '@core/models/order-product.model';
import { Select, Store } from '@ngxs/store';
import { GetProductAction } from '@store/client/product/actions/get-product.actions';
import { GetProductState } from '@store/client/product/states/get-product.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { toExtraDto } from '@core/mappers/extra.mapper';
import { OrderOption } from '@core/models/order-option.model';
import { toOptionDto } from '@core/mappers/option.mapper';
import { CurrentCategoryState } from '@store/client/category/states/current-category.state';
import { Category } from '@core/models/category.model';
import { toProductDto } from '@core/mappers/product.mapper';
import { CartAddAction } from '@store/client/cart/actions/cart.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BusinessSchedulerState } from '@store/client/business-scheduler/business-scheduler.state';
import { ExtraDto, OptionDto, ProductDto } from '../../../../../core/dtos';

@Component({
  selector: 'app-product-bottom-sheet',
  templateUrl: './product-bottom-sheet.component.html',
  styleUrls: ['./product-bottom-sheet.component.scss'],
})
export class ProductBottomSheetComponent extends CoreComponent {
  @Select(GetProductState.getProduct) getProduct$: Observable<Product>;
  @Select(CurrentCategoryState.getCurrentCategory)
  currentCategory$: Observable<Category>;
  @Select(BusinessSchedulerState.businessIsOpen)
  businessIsOpen$: Observable<boolean>;

  loading: boolean = true;

  formGroup: FormGroup;
  product: Product;
  currentCategory: Category;
  extras: Extra[];
  sort: string[];

  unitPrice: number = 0;
  quantity: number = 0;
  total: number = 0;
  formValid: boolean;

  formMap: Map<string, any> = new Map<string, any>();
  extrasOptionsMax: Map<string, number> = new Map<string, number>();
  extrasOptionsSelected: Map<string, Set<string>> = new Map<
    string,
    Set<string>
  >();

  orderProduct: OrderProduct = {} as OrderProduct;
  orderProductMap: Map<string, ExtraDto> = new Map<string, ExtraDto>();

  businessIsOpen: boolean = true;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { productId: string },
    private _bottomSheetRef: MatBottomSheetRef<ProductBottomSheetComponent>,
    private store: Store
  ) {
    super();
  }

  onInit(): void {
    this.store.dispatch(new GetProductAction(this.data.productId));

    this.currentCategory$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentCategory) => {
        if (!currentCategory) return;

        this.currentCategory = currentCategory;
      });

    this.businessIsOpen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businessIsOpen) => {
        if (businessIsOpen === null || businessIsOpen === undefined) return;
        this.businessIsOpen = businessIsOpen;
      });

    this.getProduct$.pipe(takeUntil(this.unsubscribe$)).subscribe((product) => {
      if (!product || product.productId != this.data.productId) return;

      this.loading = false;
      this.product = product;
      this.extras = product.extras;
      this.sort = product.sort;

      this.extras?.forEach((e) => {
        if (e.type === 'MULTIPLE' && e.max > 0)
          this.extrasOptionsMax.set(e.extraId, e.max);
      });

      this.unitPrice = ProductHelper.getPrice(
        this.product,
        ProductHelper.isValidDiscount(this.product)
      );
      this.initializeForm();
    });
  }

  addQuantity(val: number): void {
    this.quantity += val;
    this.updateOrder();
  }

  updateOrder() {
    if (!this.formGroup) {
      this.total = this.unitPrice * this.quantity;
      this.validExtrasForm();
      return;
    }

    let values = this.formGroup.getRawValue();
    let subtotal: number = this.unitPrice;

    this.orderProductMap = new Map<string, ExtraDto>();
    for (let key in values) {
      let value = values[key];
      if (!value) continue;

      if (value instanceof String || typeof value === 'string') {
        // type OPEN
        let extra: Extra = this.formMap.get(key);
        let ExtraDto: ExtraDto = toExtraDto(extra);
        ExtraDto.value = <string>value;
        ExtraDto.type = 'OPEN';
        this.orderProductMap.set(ExtraDto.extraId, ExtraDto);
      } else if (value instanceof Object) {
        // type UNIQUE
        let extra: Extra = this.formMap.get(key);
        let extraDto: ExtraDto = toExtraDto(extra);
        try {
          let option: Option = value;
          subtotal += option.price ?? 0;
          let orderOption: OptionDto = toOptionDto(option);
          extraDto.options = [orderOption];
          extraDto.type = 'UNIQUE';
          this.orderProductMap.set(extraDto.extraId, extraDto);
        } catch (__) {
          console.error('_', __);
        }
      } else {
        // type MULTIPLE
        let extra: Extra = this.formMap.get(key.split('_')[0]);
        let ExtraDto: ExtraDto = <ExtraDto>{};
        let orderOptions: OrderOption[];
        if (this.orderProductMap.has(extra.extraId)) {
          ExtraDto = this.orderProductMap.get(extra.extraId);
          orderOptions = ExtraDto.options;
        } else {
          ExtraDto = toExtraDto(extra);
        }
        value = this.formMap.get(key);
        try {
          let option: Option = value;
          subtotal += option.price ?? 0;
          let orderOption: OptionDto = toOptionDto(option);
          if (orderOptions) {
            orderOptions.push(orderOption);
          } else {
            orderOptions = [orderOption];
          }
          ExtraDto.options = orderOptions;
          ExtraDto.type = 'MULTIPLE';
          this.orderProductMap.set(ExtraDto.extraId, ExtraDto);
        } catch (_) {
          console.error('_', _);
        }
      }
    }
    this.total = subtotal * this.quantity;
    this.validExtrasForm();
  }

  private validExtrasForm() {
    const extraDtos: ExtraDto[] = this.getCurrentExtraDtos();
    this.formValid =
      ProductHelper.isOptionValidForExtras(this.extras, extraDtos) &&
      this.quantity > 0;
  }

  private getCurrentExtraDtos(): ExtraDto[] {
    return Array.from(this.orderProductMap.values());
  }

  onSubmit() {
    let orderProduct: ProductDto = toProductDto(this.product);
    orderProduct.categoryId = this.currentCategory.categoryId;
    orderProduct.price = this.unitPrice;
    orderProduct.quantity = this.quantity;
    orderProduct.extras = this.getCurrentExtraDtos();
    if (this.product?.promotions?.length) {
      orderProduct.promotion = this.product?.promotions[0];
    }
    this.store.dispatch(new CartAddAction(orderProduct));
    this.dismiss();
  }

  initializeForm() {
    let form: any = {};
    this.quantity = 1;
    this.total = this.unitPrice * this.quantity;
    this.validExtrasForm();

    if (!this.extras || !this.extras.length) {
      return;
    }

    for (const extra of this.extras) {
      if (extra.type === 'UNIQUE') {
        this.formMap.set(extra.extraId, extra);
        this.createUniqueField(form, extra.extraId);
      } else if (extra.type === 'MULTIPLE') {
        this.formMap.set(extra.extraId, extra);
        this.createMultipleField(form, extra);
      } else if (extra.type === 'OPEN') {
        this.formMap.set(extra.extraId, extra);
        this.createOpenField(form, extra.extraId);
      }
    }
    this.formGroup = new FormGroup(form);
    if (!this.businessIsOpen) {
      this.formGroup.disable();
    }

    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.updateOrder();
      });
  }

  validateMaxOptions(
    change: MatCheckboxChange,
    extraId: string,
    optionId: string
  ) {
    var options: Set<string> = this.extrasOptionsSelected.get(extraId);
    if (!options) {
      options = new Set<string>();
    }
    const max: number = this.extrasOptionsMax.get(extraId);
    if (change.checked) {
      options.add(optionId);
      if (max === options.size) {
        this.disableOptionsOfExtra(extraId, options);
      }
      this.extrasOptionsSelected.set(extraId, options);
    } else {
      options.delete(optionId);
      this.enableOptionsOfExtra(extraId, options);
      this.extrasOptionsSelected.set(extraId, options);
    }
  }

  private disableOptionsOfExtra(extraId: string, options: Set<string>) {
    const extra: Extra = this.extras.find((e) => e.extraId === extraId);
    extra.options.forEach((opt) => {
      if (!options.has(opt.optionId)) {
        const form: AbstractControl = this.formGroup.get(
          `${extraId}_${opt.optionId}`
        );
        form.disable();
      }
    });
  }

  private enableOptionsOfExtra(extraId: string, options: Set<string>) {
    const extra: Extra = this.extras.find((e) => e.extraId === extraId);
    extra.options.forEach((opt) => {
      if (!options.has(opt.optionId)) {
        const form: AbstractControl = this.formGroup.get(
          `${extraId}_${opt.optionId}`
        );
        if (form.disabled) form.enable();
      }
    });
  }

  createUniqueField(form: any, extraId: string) {
    form[extraId] = new FormControl();
  }

  createMultipleField(form: any, extra: Extra) {
    let extraId: string = extra.extraId;
    let options = extra.options;
    for (let option of options) {
      let key: string = `${extraId}_${option.optionId}`;
      form[key] = new FormControl(false);
      this.formMap.set(key, option);
    }
  }

  createOpenField(form: any, extraId: string) {
    form[extraId] = new FormControl();
  }

  dismiss() {
    this._bottomSheetRef.dismiss();
  }
}
