import { Injectable } from '@angular/core';
import { PersonalInfo } from '@core/models/personal-info.model';
import { LocalDataService } from '@core/services';
import { OrderType } from '@core/types/order-type.type';
import { ExtraDto, ProductDto } from '../dtos';
import { SecurityService } from '@core/services/security.service';
import { Menu } from '@core/models/menu.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _KEY: string = '__cart';

  constructor(
    private localDataSvc: LocalDataService,
    private securitySvc: SecurityService
  ) {}

  getTotal(products: Map<string, ProductDto>): number {
    if (!products) return 0;

    let total: number = 0;
    for (let orderProduct of products.values()) {
      total += this.getTotalProduct(orderProduct);
    }
    return total;
  }

  getTotalProduct(orderProduct: ProductDto) {
    if (!orderProduct) return 0;

    let subtotal = this.getSubtotalProduct(orderProduct);
    let quantity = orderProduct.quantity;
    return subtotal * quantity;
  }

  getSubtotalProduct(orderProduct: ProductDto): number {
    if (!orderProduct) return 0;

    let subtotal = orderProduct.price;
    let extras = orderProduct.extras;
    subtotal += this.getPriceExtras(extras);
    return subtotal;
  }

  getPriceExtras(extras: ExtraDto[]): number {
    if (!extras || !extras.length) return 0;

    let subtotal: number = 0;
    for (const extra of extras) {
      let options = extra.options;
      if (!options || !options.length) continue;

      for (const option of options) {
        if (!option) continue;
        subtotal += option.price ?? 0;
      }
    }
    return subtotal;
  }

  getProducts(): Map<string, ProductDto> {
    let value = this.localDataSvc.get(this._KEY);
    if (!value) return null;
    return new Map<string, ProductDto>(Object.entries(value));
  }

  setProducts(products: Map<string, ProductDto>) {
    this.localDataSvc.set(this._KEY, Object.fromEntries(products));
  }

  setProductsFromArray(products: ProductDto[]) {
    if (!products?.length) return;

    const productsMap = new Map<string, ProductDto>();
    const menu = this.getCurrentMenuFromLocal();

    products.forEach((i) => {
      menu.categories.every((c) => {
        const product = c.products?.find((p) => p.productId === i.productId);
        if (product?.promotions?.length) {
          i.promotion = product.promotions[0];
          return false;
        }
        return true;
      });
      productsMap.set(i.productOrderId, i);
    });

    this.localDataSvc.set(this._KEY, Object.fromEntries(productsMap));
  }

  clearCart() {
    this.localDataSvc.remove(this._KEY);
  }

  isPersonalInformationValid(
    orderType: OrderType,
    personalInfo: PersonalInfo
  ): boolean {
    if (!personalInfo) {
      return false;
    }
    if (orderType === 'HERE' && personalInfo.customerName) {
      return true;
    }
    if (
      orderType === 'PICK_UP' &&
      personalInfo.customerName &&
      personalInfo.phone
    ) {
      return true;
    }
    if (
      orderType === 'DELIVERY' &&
      personalInfo.customerName &&
      personalInfo.street
    ) {
      return true;
    }
    return false;
  }

  getCurrentMenuFromLocal(): Menu {
    const menuId = this.securitySvc.getDataToken(
      SecurityService.MENU_TOKEN_POSITION
    );
    return this.localDataSvc.get<Menu>(`__m${menuId}`);
  }
}
