import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import {
  CustomerAddAction,
  CustomerAddFailedAction,
  CustomerInitAction,
  CustomerAddSuccessAction,
  CustomerIsSubscribedAction,
} from './customer.actions';
import { CustomerInfo } from '@core/models/customer-info.model';
import { CustomerService } from '@core/https/customer.service';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class CustomerStateModel {
  public customer: CustomerInfo;
  public message: string;
  public isSubscribed: boolean;
  public isSuccess: boolean;
}

const defaults = {
  customer: null,
  message: null,
  isSubscribed: false,
  isSuccess: false,
};

@State<CustomerStateModel>({
  name: 'customer',
  defaults,
})
@Injectable()
export class CustomerState {
  constructor(private customerService: CustomerService) {}

  @Selector()
  public static getMessage({ message }) {
    return message;
  }

  @Selector()
  public static isSuccess({ isSuccess }) {
    return <boolean>isSuccess;
  }

  @Selector()
  public static isSubscribed({ isSubscribed }) {
    return <boolean>isSubscribed;
  }

  @Action(CustomerInitAction)
  init({ setState }: StateContext<CustomerStateModel>) {
    setState(defaults);
  }

  @Action(CustomerIsSubscribedAction)
  isBusinessSubscribed({
    getState,
    setState,
  }: StateContext<CustomerStateModel>) {
    const isSubscribed = this.customerService.isBusinessSubscribed();
    const state = getState();
    setState({ ...state, isSubscribed });
  }

  @Action(CustomerAddAction)
  registerCustomerToOffers(
    { dispatch }: StateContext<CustomerStateModel>,
    { customer }: CustomerAddAction
  ) {
    return this.customerService.registerCustomerToOffers(customer).pipe(
      tap((customer) => dispatch(new CustomerAddSuccessAction(customer))),
      catchError((error) => {
        dispatch(new CustomerAddFailedAction(error));
        return of();
      })
    );
  }

  @Action(CustomerAddSuccessAction)
  registerCustomerToOffersSuccess(
    { getState, setState }: StateContext<CustomerStateModel>,
    { customer }: CustomerAddSuccessAction
  ) {
    const state = getState();
    setState({
      ...state,
      customer,
      message: null,
      isSubscribed: true,
      isSuccess: true,
    });
  }

  @Action(CustomerAddFailedAction)
  registerCustomerToOffersFailed(
    { getState, setState }: StateContext<CustomerStateModel>,
    { message }: CustomerAddFailedAction
  ) {
    const state = getState();
    setState({
      ...state,
      message,
      customer: null,
      isSubscribed: false,
      isSuccess: false,
    });
  }
}
