import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ConsolidatePayment } from './consolidate-payment.actions';
import { StripeService } from '@core/https/stripe.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { PaymentDto } from '@core/dtos';
import { of } from 'rxjs';

export class ConsolidatePaymentStateModel {
  paid: boolean;
  error: {
    message: string;
  };
}

const defaults = {
  paid: null,
  error: null,
};

@State<ConsolidatePaymentStateModel>({
  name: 'consolidatePayment',
  defaults,
})
@Injectable()
export class ConsolidatePaymentState {
  constructor(private stripeService: StripeService) {}

  @Selector()
  static isSuccess(state: ConsolidatePaymentStateModel): boolean {
    return state.paid;
  }

  @Selector()
  static isFailed(
    state: ConsolidatePaymentStateModel
  ): ConsolidatePaymentStateModel {
    return state;
  }

  @Action(ConsolidatePayment.Add)
  add(
    { dispatch }: StateContext<ConsolidatePaymentStateModel>,
    { orderId }: ConsolidatePayment.Add
  ) {
    return this.stripeService.pay(orderId).pipe(
      tap((paymentDto: PaymentDto) => {
        if (paymentDto?.paid) {
          return dispatch(new ConsolidatePayment.Success());
        }
        return dispatch(
          new ConsolidatePayment.Failed(
            'Tu pago aun está siendo consolidado por favor intenta de nuevo.',
            paymentDto?.paid
          )
        );
      }),
      catchError((error) => {
        dispatch(
          new ConsolidatePayment.Failed(
            'Sucedió un error al tratar de consolidar el pago, por favor intenta de nuevo en 5 segundos.'
          )
        );
        console.error('Error at ConsolidatePayment.Add', error);
        return of('');
      })
    );
  }

  @Action(ConsolidatePayment.Success)
  success({ setState }: StateContext<ConsolidatePaymentStateModel>) {
    setState({
      paid: true,
      error: null,
    });
  }

  @Action(ConsolidatePayment.Failed)
  failed(
    { setState }: StateContext<ConsolidatePaymentStateModel>,
    action: ConsolidatePayment.Failed
  ) {
    setState({
      paid: action.paid,
      error: { message: action.message },
    });
  }
}
