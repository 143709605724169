<div
  [style.font-size]="isDetail ? 'large' : null"
  [style.padding-left]="isDetail ? '0.5rem' : null"
>
  <div class="prices" *ngIf="validDiscount">
    <mat-icon>discount</mat-icon>
    <span
      class="discount-percent"
      *ngIf="promotion.discountType == 'PERCENTAGE'"
      >{{ -promotion.discountValue / 100 | percent : "1.0-2" }}</span
    >
    <span
      class="discount-price right-info"
      *ngIf="promotion.discountType == 'PERCENTAGE'"
      >{{ price | currency }}</span
    >
    <span class="discount-price" *ngIf="promotion.discountType == 'PRICE'">{{
      price | currency
    }}</span>
    <span class="original-price right-info">{{
      product.price | currency
    }}</span>
  </div>
  <div class="prices" *ngIf="!validDiscount">
    <span class="price">{{ price | currency }}</span>
  </div>
</div>
