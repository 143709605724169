import { Injectable } from '@angular/core';
import { OrderService } from '@core/https/order.service';
import { PersonalInfo } from '@core/models/personal-info.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  RequestOrderAction,
  RequestOrderFailedAction,
  RequestOrderOnlinePaymentAction,
  RequestOrderSuccessAction,
} from './request-order.actions';
import { OrderDto } from '@core/dtos';
import { StripeService } from '@core/https/stripe.service';
import { OrderType } from '@core/types';

export class RequestOrderStateModel {
  public order: OrderDto;
  public message: string;
  public loading: boolean;
  public url: string;
}

const defaults = {
  order: null,
  message: null,
  loading: null,
  url: null,
};

@State<RequestOrderStateModel>({
  name: 'requestOrder',
  defaults,
})
@Injectable()
export class RequestOrderState {
  constructor(
    private orderSvc: OrderService,
    private stripeService: StripeService
  ) {}

  @Selector()
  public static get({ order }) {
    return order;
  }

  @Selector()
  public static loading({ loading }) {
    return loading;
  }

  @Selector()
  public static onlinePayment({ url }) {
    return url;
  }

  @Action(RequestOrderAction)
  request(
    ctx: StateContext<RequestOrderStateModel>,
    { orderType, deliveryFee, paymentType }: RequestOrderAction
  ) {
    const order: OrderDto = this.orderSvc.getCurrentOrder();
    const personalInfo: PersonalInfo = this.orderSvc.getPersonalInfo();

    order.type = orderType ?? order.type;
    order.deliveryFee = deliveryFee ?? order.deliveryFee ?? 0;
    order.paymentType = paymentType ?? order.paymentType;

    this.orderSvc.setCurrentOrder(order);

    this.setLoading(ctx, true);
    if (paymentType === 'ONLINE') {
      return this.stripeService.getCheckout(order.deliveryFee).pipe(
        tap((url: string) => {
          ctx.dispatch(new RequestOrderOnlinePaymentAction(url));
          return of();
        }),
        catchError((error) => {
          ctx.dispatch(new RequestOrderFailedAction(error));
          return of();
        })
      );
    }
    return this.orderSvc.request(order, personalInfo).pipe(
      tap((order) => {
        ctx.dispatch(new RequestOrderSuccessAction(order));
      }),
      catchError((error) => {
        ctx.dispatch(new RequestOrderFailedAction(error));
        return of();
      })
    );
  }

  @Action(RequestOrderSuccessAction)
  success(
    ctx: StateContext<RequestOrderStateModel>,
    { order }: RequestOrderSuccessAction
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      order: order,
      message: null,
      loading: false,
    });
  }

  @Action(RequestOrderOnlinePaymentAction)
  onlinePayment(
    { patchState }: StateContext<RequestOrderStateModel>,
    { url }: RequestOrderOnlinePaymentAction
  ) {
    patchState({
      loading: true,
      message: null,
      url,
    });
  }

  @Action(RequestOrderFailedAction)
  fail(
    ctx: StateContext<RequestOrderStateModel>,
    { message }: RequestOrderFailedAction
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      order: null,
      message: message,
      loading: false,
    });
  }

  setLoading(ctx: StateContext<RequestOrderStateModel>, loading: boolean) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      loading: loading,
    });
  }
}
