import { OptionDto } from '../dtos';
import { Option } from '@core/models/option.model';

export function toOptionDto(option: Option): OptionDto {
  return <OptionDto>{
    optionId: option.optionId,
    name: option.name,
    price: option.price,
  };
}
