import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderId',
})
export class OrderIdPipe implements PipeTransform {
  transform(orderId: string): string {
    if (!orderId?.trim()) 'No definidio';
    return orderId.split('-')[0].toUpperCase();
  }
}
