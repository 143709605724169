import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreComponent } from '@client/core/core.component';
import { OrderDto } from '@core/dtos';
import { OrderService } from '@core/https/order.service';

@Component({
  selector: 'app-cancel-checkout',
  templateUrl: './cancel-checkout.component.html',
  styleUrls: ['./cancel-checkout.component.scss'],
})
export class CancelCheckoutComponent extends CoreComponent {
  constructor(private orderService: OrderService, private router: Router) {
    super();
  }

  onInit(): void {
    const order: OrderDto = this.orderService.getCurrentOrder();
    this.router
      .navigate(['cart-confirm', order.type])
      .then((v) => console.log('then => cart-confirm/' + order.type))
      .finally(() => console.log('finally => cart-confirm/' + order.type));
  }
}
