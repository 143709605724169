import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { OrderWhatsapp } from './order-whatsapp.actions';
import { OrderService } from '@core/https/order.service';

export class OrderWhatsappStateModel {
  public send: boolean;
}

const defaults = {
  send: false,
};

@State<OrderWhatsappStateModel>({
  name: 'orderWhatsapp',
  defaults,
})
@Injectable()
export class OrderWhatsappState {
  constructor(private orderService: OrderService) {}

  @Selector()
  public static getOrderWhatsapp({ send }): boolean {
    return send;
  }

  @Action(OrderWhatsapp.Send)
  send({ setState }: StateContext<OrderWhatsappStateModel>) {
    this.orderService.setWhatsappOrder(true);
    setState({ send: true });
  }

  @Action(OrderWhatsapp.Get)
  get({ setState }: StateContext<OrderWhatsappStateModel>) {
    const send: boolean = this.orderService.getWhatsappOrder();
    setState({ send });
  }

  @Action(OrderWhatsapp.Inital)
  initial({ setState }: StateContext<OrderWhatsappStateModel>) {
    this.orderService.setWhatsappOrder(false);
    setState({ send: false });
  }
}
