import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { Select, Store } from '@ngxs/store';
import { BusinessSchedulerState } from '@store/client/business-scheduler/business-scheduler.state';
import { CartGetTotalAction } from '@store/client/cart/actions/cart.actions';
import { CartState } from '@store/client/cart/states/cart.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartComponent } from '../cart/cart.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends CoreComponent {
  @Select(CartState.getTotal) getTotal$: Observable<number>;
  @Select(BusinessSchedulerState.businessIsOpen)
  businessIsOpen$: Observable<boolean>;

  total: number = 0;
  cartAddLoading: boolean = false;
  businessIsOpen: boolean = true;

  constructor(private cartBottomSheet: MatBottomSheet, private store: Store) {
    super();
  }

  onInit(): void {
    this.store.dispatch(new CartGetTotalAction());

    this.getTotal$.pipe(takeUntil(this.unsubscribe$)).subscribe((total) => {
      if (total === null || total === undefined) return;
      this.total = total;
      this.cartAddLoading = true;
    });

    this.businessIsOpen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businessIsOpen) => {
        if (businessIsOpen === null || businessIsOpen === undefined) return;
        this.businessIsOpen = businessIsOpen;
      });
  }

  openCart(): void {
    this.cartBottomSheet.open(CartComponent, {
      hasBackdrop: true,
      panelClass: 'bottom-sheet-no-padding',
    });
  }
}
