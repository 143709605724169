import { Component, Input } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { Category } from '@core/models/category.model';
import { Menu } from '@core/models/menu.model';
import { Select, Store } from '@ngxs/store';
import {
  CurrentCategoryGetAction,
  CurrentCategorySetAction,
} from '@store/client/category/actions/current-category.actions';
import { CurrentCategoryState } from '@store/client/category/states/current-category.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent extends CoreComponent {
  @Select(CurrentCategoryState.getCurrentCategory)
  currentCategory$: Observable<Category>;

  @Input() menu: Menu;

  currentCategory: Category;

  constructor(private store: Store) {
    super();
  }

  onInit(): void {
    this.currentCategory$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentCategory) => {
        if (!currentCategory) return;

        this.currentCategory = currentCategory;
      });
  }

  setCurrentCategory(category: Category): void {
    this.store.dispatch(new CurrentCategorySetAction(category, true));
  }
}
