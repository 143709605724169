import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CallApiService {
  constructor(private http: HttpClient) {}

  get<T>({
    urlSegment,
    headers,
    isOldGateway = true,
  }: {
    urlSegment: string;
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    isOldGateway?: boolean;
  }): Observable<T> {
    const api: string = isOldGateway ? environment.api : environment.gateway;
    if (!headers) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }
    return this.http
      .get<T>(`${api}/${urlSegment}`, { headers })
      .pipe(catchError((error) => throwError(error)));
  }

  getMany<T>(
    urlSegment: string,
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        }
  ): Observable<T[]> {
    if (headers) {
      return this.http
        .get<T[]>(`${environment.api}/${urlSegment}`, { headers: headers })
        .pipe(catchError((error) => throwError(error)));
    }

    return this.http
      .get<T[]>(`${environment.api}/${urlSegment}`)
      .pipe(catchError((error) => throwError(error)));
  }

  post<T>({
    urlSegment,
    data,
    headers,
    isOldGateway = true,
  }: {
    urlSegment: string;
    data: any;
    headers?: HttpHeaders;
    isOldGateway?: boolean;
  }): Observable<void | T> {
    if (!headers) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }
    const api: string = isOldGateway ? environment.api : environment.gateway;
    return this.http
      .post<T>(`${api}/${urlSegment}`, data, {
        headers: headers,
      })
      .pipe(catchError((error) => throwError(error)));
  }

  put<T>(
    urlSegment: string,
    data: any,
    headers?: HttpHeaders
  ): Observable<void | T> {
    if (!headers) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }

    return this.http.put<T>(`${environment.api}/${urlSegment}`, data, {
      headers: headers,
    });
  }

  querySubject = new Subject<any>();
  $query = this.querySubject.asObservable();

  query<T>({
    urlSegment,
    headers,
    query,
    variables = {},
  }: {
    urlSegment: string;
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    query: string;
    variables?: any;
  }): Observable<T> {
    const api: string = environment.gateway;
    const graphql = JSON.stringify({ query, variables });
    if (!headers) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }

    return this.http
      .post<T>(`${api}/${urlSegment}`, graphql, {
        headers,
      })
      .pipe(
        map((response: any) => response.data),
        catchError((error) => throwError(error))
      );
  }
}
