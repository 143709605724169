import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CoreComponent } from '@client/core/core.component';
import { Menu } from '@core/models/menu.model';
import { OrderRequested } from '@core/models/order-requested.model';
import { Select, Store } from '@ngxs/store';
import { BusinessScheduler } from '@store/client/business-scheduler/business-scheduler.actions';
import { BusinessSchedulerState } from '@store/client/business-scheduler/business-scheduler.state';
import { OrderReaderState } from '@store/client/order/order-reader/order-reader.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends CoreComponent {
  @Input() menu: Menu;

  @Select(OrderReaderState.get) orders$: Observable<
    Map<string, OrderRequested>
  >;
  @Select(BusinessSchedulerState.businessIsOpen)
  businessIsOpen$: Observable<boolean>;

  orders: Map<string, OrderRequested>;
  businessIsOpen: boolean = true;

  constructor(private router: Router, private store: Store) {
    super();
  }

  onInit(): void {
    this.store.dispatch(new BusinessScheduler.Get());
    this.orders$.pipe(takeUntil(this.unsubscribe$)).subscribe((orders) => {
      if (!orders) return;
      this.orders = orders;
    });
    this.businessIsOpen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businessIsOpen) => {
        if (businessIsOpen === null || businessIsOpen === undefined) return;
        this.businessIsOpen = businessIsOpen;
      });
  }

  goToOrders() {
    this.router.navigate(['orders']);
  }
}
