import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CoreComponent } from '@client/core/core.component';
import { Menu } from '@core/models/menu.model';
import { OrderType } from '@core/types/order-type.type';
import { Select, Store } from '@ngxs/store';
import { GetMenuAction } from '@store/client/menu/actions/get-menu.actions';
import { GetMenuState } from '@store/client/menu/states/get-menu.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-type-selector',
  templateUrl: './order-type-selector.component.html',
  styleUrls: ['./order-type-selector.component.scss'],
})
export class OrderTypeSelectorComponent extends CoreComponent {
  @Select(GetMenuState.getMenu) menu$: Observable<Menu>;

  menu: Menu;

  constructor(
    public store: Store,
    private router: Router,
    private _bottomSheetRef: MatBottomSheetRef<OrderTypeSelectorComponent>
  ) {
    super();
  }

  onInit(): void {
    this.menu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu: Menu) => {
      if (!menu) return;

      this.menu = menu;
    });
    if (!this.menu) this.store.dispatch(new GetMenuAction());
  }

  async goTo(orderType: OrderType) {
    const navigated: boolean = await this.router.navigate([
      'cart-confirm',
      orderType,
    ]);
    if (navigated) this.dismiss();
  }

  private dismiss() {
    this._bottomSheetRef.dismiss();
  }
}
