<div class="row">
  <div class="col-xs-1 product-arrow-back">
    <mat-icon color="primary" (click)="dismiss()">arrow_back</mat-icon>
  </div>
  <div class="col-xs-11">
    <h3>Tu orden ya ha sido recibida, avisa a tu mesero.</h3>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <span>Folio {{id}}</span>
  </div>
  <div class="col-xs-12">
    <img src="../../../../../assets/images/well-done.png" class="img-well-done">
  </div>
</div>
