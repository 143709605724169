export class LoginAction {
  static readonly type = '[Login]';
  constructor(public key: string) {}
}

export class LoginFailedAction {
  static readonly type = '[Login] Failed';
  constructor(public message: string) {}
}

export class LoginInitializedAction {
  static readonly type = '[Login] Initialized';
  constructor() {}
}

export class LoginSuccessAction {
  static readonly type = '[Login] Success';
  constructor(public idMenu: string) {}
}
