<div class="row">
  <div class="col-xs-9">
    <mat-list>
      <div mat-subheader>Método de pago</div>
      <mat-list-item>
        <mat-icon mat-list-icon *ngIf="!paymentType"
          >account_balance_wallet</mat-icon
        >
        <mat-icon mat-list-icon *ngIf="paymentType">{{
          paymentType | paymentTypeIcon
        }}</mat-icon>
        <div
          mat-line
          class="add-info"
          (click)="
            availablePaymentTypes?.length && availablePaymentTypes.length > 1
              ? openPaymentTypeSelector()
              : false
          "
        >
          <span *ngIf="!paymentType">Seleccióna el método de pago</span>
          <div mat-line *ngIf="paymentType">
            {{ paymentType | paymentType }}
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col-xs-3 change">
    <span
      *ngIf="availablePaymentTypes?.length && availablePaymentTypes.length > 1"
      (click)="openPaymentTypeSelector()"
      >Cambiar</span
    >
    <span
      *ngIf="
        !availablePaymentTypes?.length || availablePaymentTypes.length <= 1
      "
    ></span>
  </div>
</div>
