<div *ngIf="loading && !order">
  <app-loading message="Enviando pedido"></app-loading>
</div>

<div *ngIf="confirmationLoading">
  <app-loading message="Validando información"></app-loading>
</div>

<div *ngIf="!loading && !order">
  <div class="row cart-title-stick">
    <mat-icon class="col-xs-1 arrow-back" color="primary" (click)="goToMenu()"
      >arrow_back</mat-icon
    >
    <span class="cart-title col-xs-11">Información del pedido</span>
  </div>
  <app-order-type-detail></app-order-type-detail>
  <app-payment-type></app-payment-type>
  <app-products-detail></app-products-detail>
  <app-cart-confirm-footer></app-cart-confirm-footer>
</div>
