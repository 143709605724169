import { Pipe, PipeTransform } from '@angular/core';
import { ProductDto } from '../../../core/dtos';
import { CartService } from 'core/https/cart.service';

@Pipe({
  name: 'totalProductPrice',
})
export class TotalProductPricePipe implements PipeTransform {
  constructor(private cardSvc: CartService) {}

  transform(orderProduct: ProductDto): number {
    if (!orderProduct) return 0;

    let total: number = this.cardSvc.getTotalProduct(orderProduct);
    return total;
  }
}
