import { Category } from '@core/models/category.model';

export class CurrentCategorySetAction {
  static readonly type = '[CurrentCategory] Set';
  constructor(public category: Category, public refresh: boolean) {}
}

export class CurrentCategoryFailedAction {
  static readonly type = '[CurrentCategory] Failed';
  constructor() {}
}

export class CurrentCategoryGetAction {
  static readonly type = '[CurrentCategory] Get';
  constructor() {}
}

export class CurrentCategorySuccessAction {
  static readonly type = '[CurrentCategory] Success';
  constructor(public category: Category) {}
}
