import { Component } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { Category } from '@core/models/category.model';
import { Select } from '@ngxs/store';
import { CurrentCategoryState } from '@store/client/category/states/current-category.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent extends CoreComponent {
  @Select(CurrentCategoryState.getCurrentCategory)
  currentCategory$: Observable<Category>;

  currentCategory: Category;

  constructor() {
    super();
  }

  onInit(): void {
    this.currentCategory$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentCategory) => {
        if (!currentCategory) return;

        this.currentCategory = currentCategory;
      });
  }
}
