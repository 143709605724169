import { ExtraDto } from '../../../../core/dtos';
import { Extra } from '@core/models/extra.model';
import { Product } from '@core/models/product.model';
import { Promotion } from '@core/models/promotion.model';

export class ProductHelper {
  static isValidDiscount(product: Product): boolean {
    if (!product.promotions?.length) return false;

    const promotion: Promotion = product.promotions[0];
    switch (promotion.promotionType) {
      case 'ALWAYS':
        return true;
      case 'DATE':
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);

        const todayUnix = Math.floor(new Date(today).getTime() / 1000);
        const startDateUnix = Math.floor(
          new Date(promotion.dateStart).getTime() / 1000
        );
        const endDateUnix = Math.floor(
          new Date(promotion.dateEnd).getTime() / 1000
        );

        if (todayUnix >= startDateUnix && todayUnix <= endDateUnix) {
          return true;
        }
        break;
      case 'DAYS':
        const now: Date = new Date();
        if (promotion.promotionType === 'DAYS') {
          const day: any = this.DAYS.find((d) => d.i === now.getDay());
          if (promotion.days.some((d) => d === day?.d)) {
            return true;
          }
        }
        break;
      default:
        return false;
    }
    return false;
  }

  static getPrice(product: Product, isValidDiscount: boolean): number {
    let price: number;
    const promotion: Promotion = product.promotions?.length
      ? product.promotions[0]
      : null;
    if (!isValidDiscount) {
      price = product.price;
    } else if (promotion.discountType === 'PERCENTAGE') {
      price = product.price * (1 - promotion.discountValue / 100);
    } else if (promotion.discountType === 'PRICE') {
      price = promotion.discountValue;
    } else {
      price = product.price;
    }
    price = Number(price.toFixed(2));
    return price;
  }

  static isOptionValidForExtras(extras: Extra[], order: ExtraDto[]): boolean {
    if (!extras || !extras.length) {
      return true;
    }
    extras = extras.filter((v) => v.type != 'OPEN' && !v.optional);
    if (!extras || !extras.length) {
      return true;
    }
    for (let index = 0; index < extras.length; index++) {
      const extra = extras[index];
      const some: boolean = order.some((v) => v.extraId === extra.extraId);
      if (!some) return some;
    }
    return true;
  }

  private static DAYS: any[] = [
    { i: 0, d: 'SUNDAY' },
    { i: 1, d: 'MONDAY' },
    { i: 2, d: 'TUESDAY' },
    { i: 3, d: 'WEDNESDAY' },
    { i: 4, d: 'THURSDAY' },
    { i: 5, d: 'FRIDAY' },
    { i: 6, d: 'SATURDAY' },
  ];
}
