<app-banner></app-banner>
<header class="row" *ngIf="menu">
  <div class="header-col">
    <div class="box">
      <div *ngIf="menu?.backgroundUrl; else nameBlock">
        <img
          class="header__background"
          *ngIf="menu?.backgroundUrl"
          src="{{ menu?.backgroundUrl }}"
        />
      </div>
      <ng-template #nameBlock>
        <h2 *ngIf="menu?.showName">{{ menu?.name }}</h2>
      </ng-template>
    </div>
  </div>
  <!--<mat-icon class="icon_orders" [matBadge]="orders.size" matBadgeColor="warn" *ngIf="orders" (click)="goToOrders()">notifications_active</mat-icon>-->
  <div *ngIf="!businessIsOpen" class="closed">
    <span class="closed_span">
      <p class="closed_text">
        Lo sentimos aún no abrimos, regresaremos pronto.
      </p>
    </span>
  </div>
</header>
