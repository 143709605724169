import { NgModule } from '@angular/core';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'environments/environment';

import { CoreModule } from '@core/core.module';
import { CurrentCategoryState } from './client/category/states/current-category.state';
import { GetMenuState } from './client/menu/states/get-menu.state';
import { LoginState } from './security/states/login.state';
import { GetProductState } from './client/product/states/get-product.state';
import { CartState } from './client/cart/states/cart.state';
import { PersonalInfoState } from './client/personal-info/personal-info.state';
import { CreateOrderState } from './client/order/create-order/create-order.state';
import { RequestOrderState } from './client/order/request-order/request-order.state';
import { OrderTypeState } from './client/order/order-type/order-type.state';
import { OrderReaderState } from './client/order/order-reader/order-reader.state';
import { CustomerState } from './client/customer/customer.state';
import { BusinessSchedulerState } from './client/business-scheduler/business-scheduler.state';
import { ConfirmOrderState } from './client/order/confirm-order/confirm-order.state';
import { PaymentTypeState } from './client/order/payment-type/payment-type.state';
import { OrderWhatsappState } from './client/order/order-whatsapp/order-whatsapp.state';
import { ConsolidatePaymentState } from './client/payment/consolidate-payment/consolidate-payment.state';

@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsModule.forRoot(
      [
        CartState,
        ConfirmOrderState,
        CreateOrderState,
        CurrentCategoryState,
        CustomerState,
        GetMenuState,
        GetProductState,
        LoginState,
        OrderTypeState,
        OrderReaderState,
        PaymentTypeState,
        PersonalInfoState,
        RequestOrderState,
        BusinessSchedulerState,
        OrderWhatsappState,
        ConsolidatePaymentState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
})
export class StateModule {}
