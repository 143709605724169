import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@core/models/option.model';

@Pipe({
  name: 'sortOptions',
})
export class SortOptionsPipe implements PipeTransform {
  transform(options: Option[], sort: string[]): Option[] {
    const sortedOptions: Option[] = [];
    for (const optionId of sort) {
      const option: Option = options.find((o) => o.optionId === optionId);
      if (option) {
        sortedOptions.push(option);
      }
    }
    return sortedOptions;
  }
}
