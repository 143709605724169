<div class="row">
  <div class="col-xs-12">
    <mat-list>
      <div mat-subheader>
        Pedido del {{ order.createdAt | date : "d/M/yy, h:mm a" }}<br />Folio:
        {{ order.order.orderId }}
      </div>
      <div class="col-xs-12 order-type">
        <span
          >Tipo de pedido: <b>{{ order.order.type | orderType }}</b></span
        >
        <button
          class="button-qr"
          mat-raised-button
          *ngIf="order.order.type === 'HERE'"
          (click)="openQr()"
        >
          <mat-icon>search</mat-icon>Ver...
        </button>
      </div>
      //--
      <app-product-detail
        *ngFor="let product of order.order"
        [product]="product"
      ></app-product-detail>
      <mat-list-item>
        <div mat-line>
          <span class="col-xs-12 total">Total: {{ total | currency }}</span>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>
</div>
