import { CustomerInfo } from '@core/models/customer-info.model';

export class CustomerInitAction {
  static readonly type = '[Customer] Init';
}

export class CustomerIsSubscribedAction {
  static readonly type = '[Customer] Is subscribed';
  constructor() {}
}

export class CustomerAddAction {
  static readonly type = '[Customer] Add customer';
  constructor(public customer: CustomerInfo) {}
}

export class CustomerAddSuccessAction {
  static readonly type = '[Customer] Add customer success';
  constructor(public customer: CustomerInfo) {}
}

export class CustomerAddFailedAction {
  static readonly type = '[Customer] Add customer failed';
  constructor(public message: string) {}
}
