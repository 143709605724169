import { Injectable } from '@angular/core';
import { SecurityService } from '@core/services/security.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  LoginAction,
  LoginFailedAction,
  LoginInitializedAction,
  LoginSuccessAction,
} from '../actions/login.actions';

export class LoginStateModel {
  public loading: boolean;
  public idMenu: string;
  public message: string;
}

const defaults = {
  loading: false,
  idMenu: null,
  message: null,
};

@State<LoginStateModel>({
  name: 'login',
  defaults,
})
@Injectable()
export class LoginState {
  constructor(private securitySvc: SecurityService) {}

  @Selector()
  public static logging({ loading }) {
    return loading;
  }

  @Selector()
  public static getIdMenu({ idMenu }) {
    return idMenu;
  }

  @Selector()
  public static getError({ message }) {
    return message;
  }

  @Action(LoginAction)
  get(ctx: StateContext<LoginStateModel>, { key }: LoginAction) {
    this.getting(ctx);

    return this.securitySvc.login(key).pipe(
      tap((idMenu) => {
        ctx.dispatch(new LoginSuccessAction(idMenu));
      }),
      catchError((error) => {
        ctx.dispatch(new LoginFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(LoginFailedAction)
  fail(ctx: StateContext<LoginStateModel>, { message }: LoginFailedAction) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      idMenu: null,
      message: message,
    });
  }

  @Action(LoginInitializedAction)
  initialize(ctx: StateContext<LoginStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: true,
      idMenu: null,
      message: null,
    });
  }

  @Action(LoginSuccessAction)
  success(ctx: StateContext<LoginStateModel>, { idMenu }: LoginSuccessAction) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: true,
      idMenu: idMenu,
      message: null,
    });
  }

  private getting(ctx: StateContext<LoginStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
