import { Injectable } from '@angular/core';
import { Business } from '@core/models/business.model';
import { CallApiService, LocalDataService } from '@core/services';
import { SecurityService } from '@core/services/security.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private _SEGMENT: string = 'businesses';
  private _KEY: string = '__business';

  constructor(
    private callApiService: CallApiService,
    private securitySvc: SecurityService,
    private localDataSvc: LocalDataService
  ) {}

  getBusiness(): Observable<Business> {
    const query = `
            query ($businessId: String!){
              business(businessId: $businessId) {
                isOpen
                allowPayments
                stripeUserId
              }
            }
    `;
    const businessId = this.securitySvc.getDataToken(
      SecurityService.BUSINESS_TOKEN_POSITION
    );
    const variables = { businessId };
    return this.callApiService
      .query<Business>({
        urlSegment: `${this._SEGMENT}/graphql`,
        query,
        variables,
      })
      .pipe(
        tap((business: any) =>
          this.saveCurrentBusinness({ business: business.business })
        ),
        map((business: any) => business.business),
        catchError((error) => throwError(error))
      );
  }

  saveCurrentBusinness({ business }: { business: Business }) {
    this.localDataSvc.set(this._KEY, business);
  }
}
