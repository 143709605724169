import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '@core/models/category.model';

@Pipe({
  name: 'sortCategories',
})
export class SortCategoriesPipe implements PipeTransform {
  transform(categories: Category[], sort: string[]): Category[] {
    const sortedCategories: Category[] = [];
    for (const o of sort) {
      if (categories.some((c) => c.categoryId === o)) {
        const category: Category = categories.find((c) => c.categoryId === o);
        sortedCategories.push(category);
      }
    }

    return sortedCategories;
  }
}
