import { OrderDto, ProductDto } from '../dtos';
import { Address } from '@core/models/address.model';
import { OrderRequest } from '@core/models/order-request.model';
import { PersonalInfo } from '@core/models/personal-info.model';
import { PaymentType } from '@core/types/payment.type';

export function toOrder(products: ProductDto[], businessId: string): OrderDto {
  return <OrderDto>{
    /*
    businessId: businessId,
    products: products,*/
  };
}

export function toOrderRequest(
  order: OrderDto,
  personalInfo: PersonalInfo
): OrderRequest {
  let address: Address;
  if (order.type === 'DELIVERY') {
    if (!personalInfo) {
      throw new Error('Invalid personalInfo');
    }
    address = <Address>{
      neighborhood: personalInfo.neighborhood,
      references: personalInfo.reference ?? undefined,
      street: personalInfo.street,
      zip: personalInfo.zip,
    };
  }

  return <OrderRequest>{
    address,
    customerName: personalInfo?.customerName,
    createdIn: order.createdIn,
    deliveryFee: order.deliveryFee ?? 0,
    orderId: order.orderId,
    type: order.type,
    phone: personalInfo?.phone,
    status: 'REQUESTED',
  };
}
