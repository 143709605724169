import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material/material.module';
import { MenuComponent } from './pages/menu/menu.component';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from '@client/components/header/header.component';
import { ClientRoutingModule } from '@client/client-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { VerifyInterceptor } from '@core/interceptors/verify.interceptor';
import { CategoriesComponent } from './components/menu/categories/categories.component';
import { SortCategoriesPipe } from './pipes/sort-categories.pipe';
import { CategoryComponent } from './components/menu/category/category.component';
import { SortProductsPipe } from './pipes/sort-products.pipe';
import { ProductComponent } from './components/menu/product/product.component';
import { ProductBottomSheetComponent } from './components/menu/product-bottom-sheet/product-bottom-sheet.component';
import { CategoryScrollBehaviorDirective } from './directives/category-scroll-behavior.directive';
import { FooterComponent } from './components/menu/footer/footer.component';
import { CartComponent } from './components/menu/cart/cart.component';
import { ProductLabelDiscountComponent } from './components/menu/product-label-discount/product-label-discount.component';
import { CardCartComponent } from './components/menu/cart/card-cart/card-cart.component';
import { TotalProductPricePipe } from './pipes/total-product-price.pipe';
import { DescribeExtrasPipe } from './pipes/describe-extras.pipe';
import { CartConfirmComponent } from './pages/cart-confirm/cart-confirm.component';
import { OrderTypeSelectorComponent } from './components/menu/order-type-selector/order-type-selector.component';
import { OrderTypeDetailComponent } from './components/menu/cart-confirm/order-type-detail/order-type-detail.component';
import { OrderTypePipe } from './pipes/order-type.pipe';
import { PersonalInfoComponent } from './components/menu/personal-info/personal-info.component';
import { ProductsDetailComponent } from './components/menu/cart-confirm/products-detail/products-detail.component';
import { ProductDetailComponent } from './components/menu/cart-confirm/product-detail/product-detail.component';
import { CartConfirmFooterComponent } from './components/menu/cart-confirm/cart-confirm-footer/cart-confirm-footer.component';
import { MyOrdersComponent } from './pages/orders/my-orders/my-orders.component';
import { MyProductsDetailComponent } from './components/orders/my-products-detail/my-products-detail.component';
import { OrderQrComponent } from './components/orders/order-qr/order-qr.component';

import { QRCodeModule } from 'angularx-qrcode';
import { OrderQrBottomSheetComponent } from './components/orders/order-qr-bottom-sheet/order-qr-bottom-sheet.component';
import { BannerComponent } from './components/banner/banner.component';
import { SortOptionsPipe } from './pipes/sort-options.pipe';
import { SortExtrasPipe } from './pipes/sort-extras.pipe';
import { PaymentTypeComponent } from './components/menu/cart-confirm/payment-type/payment-type.component';
import { PaymentTypeSelectorComponent } from './components/menu/payment-type-selector/payment-type-selector.component';
import { PaymentTypePipe } from './pipes/payment-type.pipe';
import { PaymentTypeIconPipe } from './pipes/payment-type-icon.pipe';
import { OrderRequestedComponent } from './pages/orders/order-requested/order-requested.component';
import { OrderIdPipe } from './pipes/order-id.pipe';
import { CheckoutComponent } from './pages/payment/checkout/checkout.component';
import { CancelCheckoutComponent } from './pages/payment/cancel-checkout/cancel-checkout.component';

@NgModule({
  declarations: [
    MenuComponent,
    HeaderComponent,
    CategoriesComponent,
    SortCategoriesPipe,
    CategoryComponent,
    SortProductsPipe,
    ProductComponent,
    ProductBottomSheetComponent,
    CategoryScrollBehaviorDirective,
    FooterComponent,
    CartComponent,
    ProductLabelDiscountComponent,
    CardCartComponent,
    TotalProductPricePipe,
    DescribeExtrasPipe,
    CartConfirmComponent,
    OrderTypeSelectorComponent,
    OrderTypeDetailComponent,
    OrderTypePipe,
    PersonalInfoComponent,
    ProductsDetailComponent,
    ProductDetailComponent,
    CartConfirmFooterComponent,
    MyOrdersComponent,
    MyProductsDetailComponent,
    OrderQrComponent,
    OrderQrBottomSheetComponent,
    BannerComponent,
    SortOptionsPipe,
    SortExtrasPipe,
    PaymentTypeComponent,
    PaymentTypeSelectorComponent,
    PaymentTypePipe,
    PaymentTypeIconPipe,
    OrderRequestedComponent,
    OrderIdPipe,
    CheckoutComponent,
    CancelCheckoutComponent,
  ],
  imports: [
    ClientRoutingModule,
    CommonModule,
    MaterialModule,
    QRCodeModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VerifyInterceptor,
      multi: true,
    },
  ],
})
export class ClientModule {}
