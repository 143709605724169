export namespace OrderWhatsapp {
  export class Inital {
    static readonly type = '[OrderWhatsapp] Inital';
    constructor() {}
  }

  export class Send {
    static readonly type = '[OrderWhatsapp] Send';
    constructor() {}
  }

  export class Get {
    static readonly type = '[OrderWhatsapp] Get';
    constructor() {}
  }
}
