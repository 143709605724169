import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '@core/types/payment.type';

@Pipe({
  name: 'paymentType',
})
export class PaymentTypePipe implements PipeTransform {
  transform(paymentType: PaymentType): string {
    if (paymentType === 'ONLINE') {
      return 'Pago en línea';
    }
    if (paymentType === 'ON_SITE') {
      return 'Pago contra entrega';
    }
    return 'No definido';
  }
}
