import { Injectable } from '@angular/core';
import { CallApiService, LocalDataService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrderService } from './order.service';
import { PersonalInfo } from '../models/personal-info.model';
import { CartService } from './cart.service';
import { OrderDto, PaymentDto, ProductDto } from '../dtos';
import { OrderType } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private _SEGMENT: string = 'stripe';
  private _PRE_PAY: string = '_PRE_PAY';

  constructor(
    private callApiService: CallApiService,
    private orderService: OrderService,
    private cartService: CartService,
    private localDataService: LocalDataService
  ) {}

  getCheckout(deliveryFee: number): Observable<string> {
    const currentOrder: OrderDto = this.orderService.getCurrentOrder();
    const products: Map<string, ProductDto> = this.cartService.getProducts();
    let total: number = this.cartService.getTotal(products);
    if (deliveryFee) {
      total += deliveryFee;
    }
    if (total <= 10) {
      throwError('El monto mínimo debe ser mayor a 10');
    }
    const personalInfo: PersonalInfo = this.orderService.getPersonalInfo();
    return this.callApiService
      .post<string>({
        urlSegment: this._SEGMENT + '/checkout/' + currentOrder.orderId,
        data: {
          customerName: personalInfo.customerName.split(' ')[0],
          total,
        },
        isOldGateway: false,
      })
      .pipe(
        map((data: any) => data.url),
        catchError((error) => throwError(error))
      );
  }

  pay(orderId: string): Observable<PaymentDto> {
    return this.callApiService
      .post<PaymentDto>({
        urlSegment: '/payments/' + orderId,
        data: {},
        isOldGateway: false,
      })
      .pipe(
        map((data: PaymentDto) => data),
        catchError((error) => throwError(error))
      );
  }

  saveDataPrePay({
    deliveryFee,
    orderType,
  }: {
    deliveryFee: number;
    orderType: OrderType;
  }): void {
    this.localDataService.set(this._PRE_PAY, { deliveryFee, orderType });
  }

  getDataPrePay(): any {
    return this.localDataService.get(this._PRE_PAY);
  }
}
