import { ProductDto } from '../../../../core/dtos';

export class CartAddAction {
  static readonly type = '[Cart] Add';
  constructor(public productDto: ProductDto) {}
}

export class CartGetTotalAction {
  static readonly type = '[Cart] Get total';
  constructor() {}
}

export class CartGetAction {
  static readonly type = '[Cart] Get';
  constructor() {}
}

export class CartChangeQuantityAction {
  static readonly type = '[Cart] Change quantity';
  constructor(public id: string, public quantity: number) {}
}
