<mat-card class="main-card">
  <mat-card-title-group>
    <mat-card-title class="title">{{ product.name }}</mat-card-title>
  </mat-card-title-group>
  <mat-card-content class="main-card-content">
    <span>{{ product.extras | describeExtras }}</span>
    <div>
      <mat-icon class="promotion" *ngIf="product.promotion">discount</mat-icon>
      <span class="price">{{ product | totalProductPrice | currency }}</span>
    </div>
  </mat-card-content>
  <mat-card-actions class="card-actions-buttons">
    <button
      mat-button
      *ngIf="product.quantity <= 1"
      (click)="changeQuantity(-1)"
    >
      <mat-icon class="font-x-large delete">delete_outline</mat-icon>
    </button>
    <button
      class="font-x-large"
      mat-button
      *ngIf="product.quantity > 1"
      (click)="changeQuantity(-1)"
    >
      -
    </button>
    <span class="quantity">{{ product.quantity }}</span>
    <button class="font-x-large" mat-button (click)="changeQuantity(1)">
      +
    </button>
  </mat-card-actions>
</mat-card>
