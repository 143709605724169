import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { OrderTypeAction } from './order-type.actions';
import { OrderType } from '@core/types/order-type.type';

export class OrderTypeStateModel {
  public orderType: OrderType;
}

const defaults = {
  orderType: null,
};

@State<OrderTypeStateModel>({
  name: 'orderType',
  defaults,
})
@Injectable()
export class OrderTypeState {
  @Selector()
  public static getOrderType({ orderType }) {
    return orderType;
  }

  @Action(OrderTypeAction)
  set(ctx: StateContext<OrderTypeStateModel>, { orderType }: OrderTypeAction) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      orderType: orderType,
    });
  }
}
