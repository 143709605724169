<footer class="col-bottom">
  <div class="row">
    <div class="col col-xs-5 total">
      <span>Total: {{ total | currency }}</span>
    </div>
    <div class="col col-xs-7">
      <button
        mat-raised-button
        *ngIf="businessIsOpen"
        (click)="openCart()"
        [disabled]="total === 0"
      >
        Ver mi pedido
      </button>
      <button mat-raised-button *ngIf="!businessIsOpen" disabled="true">
        Negocio cerrado
      </button>
    </div>
  </div>
</footer>
