import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { Business } from '@core/models/business.model';
import { PaymentType } from '@core/types/payment.type';
import { Select, Store } from '@ngxs/store';
import { BusinessSchedulerState } from '@store/client/business-scheduler/business-scheduler.state';
import { PaymentTypeActions } from '@store/client/order/payment-type/payment-type.actions';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-type-selector',
  templateUrl: './payment-type-selector.component.html',
  styleUrls: ['./payment-type-selector.component.scss'],
})
export class PaymentTypeSelectorComponent extends CoreComponent {
  @Select(BusinessSchedulerState.getBusiness)
  getBusiness$: Observable<Business>;

  business: Business;
  public availablePaymentTypes: PaymentType[] = ['ON_SITE'];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<PaymentTypeSelectorComponent>,
    public store: Store
  ) {
    super();
  }

  onInit(): void {
    this.getBusiness$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business: Business) => {
        if (!business) return;

        this.business = business;
        if (this.business.allowPayments && this.business.stripeUserId)
          this.availablePaymentTypes.push('ONLINE');
      });
  }

  setPaymentType(paymentType: PaymentType) {
    this.store.dispatch(new PaymentTypeActions.Set(paymentType));
    this.dismiss();
  }

  private dismiss() {
    this._bottomSheetRef.dismiss();
  }
}
