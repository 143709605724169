<div class="row--not-margin">
  <div class="col-xs-12">
    <div class="box">
      <mat-spinner></mat-spinner>
      <div class="loading__message" *ngIf="messages.length === 0">
        {{ message }}
      </div>
      <div class="loading_box" *ngIf="messages.length > 0">
        <span class="loading__message">{{
          messages[currentMessageIndex]
        }}</span>
      </div>
    </div>
  </div>
</div>
