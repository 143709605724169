import { Component } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { LoginComponent } from '@client/core/login.component';
import { Category } from '@core/models/category.model';
import { Menu } from '@core/models/menu.model';
import { Select, Store } from '@ngxs/store';
import { CartState } from '@store/client/cart/states/cart.state';
import { CurrentCategorySetAction } from '@store/client/category/actions/current-category.actions';
import { GetMenuState } from '@store/client/menu/states/get-menu.state';
import { ConfirmOrderState } from '@store/client/order/confirm-order/confirm-order.state';
import { OrderReader } from '@store/client/order/order-reader/order-reader.actions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends LoginComponent {
  @Select(GetMenuState.getMenu) menu$: Observable<Menu>;
  @Select(GetMenuState.getting) loading$: Observable<boolean>;
  @Select(GetMenuState.getError) error$: Observable<string>;
  @Select(CartState.getAddProductLoading)
  addProductLoading$: Observable<boolean>;
  @Select(ConfirmOrderState.isLoadingConfirmFromPointOfSale)
  isLoadingConfirmFromPointOfSale$: Observable<boolean>;
  @Select(ConfirmOrderState.isSuccessFromPointOfSale)
  isSuccessFromPointOfSale$: Observable<boolean>;

  menu: Menu;
  addProductLoading = false;
  confirmFromPointOfSaleLoading = false;
  apiKey;

  messages: string[] = [
    '¡Los sabores están en camino!',
    '¿Has tomado tu café hoy?',
    'El postre no engorda si lo comes de pie',
    '¡Preparando tu selección!',
    'Dándole el toque final',
    '¡Ya casi está listo!',
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public ddsvc: DeviceDetectorService,
    public store: Store,
    private _snackBar: MatSnackBar
  ) {
    super(activatedRoute, ddsvc, store);
  }

  onInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: Params) => {
        if (!params) {
          return;
        }
        const apiKey = params['apikey'];
        if (!apiKey) {
          return;
        }
        this.apiKey = apiKey;
        this.showSnackBar('Modo punto de venta activado', 1500);
      });

    if (!this.apiKey && !this.isMobile) {
      this.message = 'Esta vista sólo está disponible para smartphone.';
      this.error = true;
      this.loading = false;
      return;
    }

    this.error$.pipe(takeUntil(this.unsubscribe$)).subscribe((errorMessage) => {
      if (errorMessage) {
        this.error = true;
        this.message = errorMessage;
      }
    });

    this.loading$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      this.loading = loading;
    });

    this.addProductLoading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((addProductLoading) => {
        this.addProductLoading = addProductLoading;
      });

    this.isLoadingConfirmFromPointOfSale$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoadingConfirmFromPointOfSale) => {
        this.confirmFromPointOfSaleLoading = isLoadingConfirmFromPointOfSale;
      });

    this.isSuccessFromPointOfSale$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isSuccessFromPointOfSale) => {
        if (!isSuccessFromPointOfSale) {
          return;
        }
        this.showSnackBar('Pedido guardado');
      });

    this.menu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu: Menu) => {
      if (menu) {
        this.error = false;
        this.menu = menu;

        if (this.menu.categories) {
          const currentCategory: Category = this.menu.categories.find(
            (c) => c.categoryId === this.menu.sort[0]
          );

          this.store.dispatch(
            new CurrentCategorySetAction(currentCategory, false)
          );
        }
      }
    });

    this.store.dispatch(new OrderReader.FromRepository());
  }

  private showSnackBar(
    message: string,
    duration: number = 2000,
    position: MatSnackBarVerticalPosition = 'bottom'
  ) {
    this._snackBar.open(message, 'Cerrar', {
      duration,
      panelClass: ['mat-toolbar', 'mat-primary'],
      verticalPosition: position,
    });
  }
}
