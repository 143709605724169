import { Component, Input, OnInit } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { CartService } from '@core/https/cart.service';
import { Menu } from '@core/models/menu.model';
import { PersonalInfo } from '@core/models/personal-info.model';
import { OrderType } from '@core/types/order-type.type';
import { PaymentType } from '@core/types/payment.type';
import { Select, Store } from '@ngxs/store';
import { CartState } from '@store/client/cart/states/cart.state';
import { GetMenuState } from '@store/client/menu/states/get-menu.state';
import { OrderTypeState } from '@store/client/order/order-type/order-type.state';
import { PaymentTypeState } from '@store/client/order/payment-type/payment-type.state';
import { RequestOrderAction } from '@store/client/order/request-order/request-order.actions';
import { PersonalInfoState } from '@store/client/personal-info/personal-info.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cart-confirm-footer',
  templateUrl: './cart-confirm-footer.component.html',
  styleUrls: ['./cart-confirm-footer.component.scss'],
})
export class CartConfirmFooterComponent extends CoreComponent {
  @Select(CartState.getTotal) getTotal$: Observable<number>;
  @Select(PersonalInfoState.get) getPersonalInfo$: Observable<PersonalInfo>;
  @Select(OrderTypeState.getOrderType) getOrderType$: Observable<OrderType>;
  @Select(GetMenuState.getMenu) getMenu$: Observable<Menu>;
  @Select(PaymentTypeState.getPaymentType)
  getPaymentType$: Observable<PaymentType>;

  total: number = 0;
  menu: Menu;
  personalInfo: PersonalInfo = null;
  isValidInformation: boolean;
  orderType: OrderType;
  priceWithoutFee: boolean = true;
  paymentType: PaymentType = null;

  constructor(private store: Store, private cartSvc: CartService) {
    super();
  }

  onInit(): void {
    this.getMenu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu) => {
      if (!menu) return;

      this.menu = menu;
    });

    this.getOrderType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orderType) => {
        if (!orderType) return;

        this.orderType = orderType;
        if (this.orderType === 'DELIVERY') {
          this.total += this.menu.deliveryFee ?? 0;
          this.priceWithoutFee = false;
        } else {
          if (!this.priceWithoutFee) {
            this.total -= this.menu.deliveryFee ?? 0;
            this.priceWithoutFee = true;
          }
        }

        this.validateInformation();
      });

    this.getTotal$.pipe(takeUntil(this.unsubscribe$)).subscribe((total) => {
      if (this.orderType) {
        this.total = total;
        if (this.orderType === 'DELIVERY') {
          this.total += this.menu.deliveryFee ?? 0;
          this.priceWithoutFee = false;
        }
      }
    });

    this.getPersonalInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((personalInfo) => {
        if (!personalInfo) {
          return;
        }
        this.personalInfo = personalInfo;
        this.validateInformation();
      });

    this.getPaymentType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((paymentType: PaymentType) => {
        if (!paymentType) return;

        this.paymentType = paymentType;
      });
  }

  requestOrder() {
    this.store.dispatch(
      new RequestOrderAction(
        this.orderType,
        this.orderType === 'DELIVERY' ? this.menu.deliveryFee : 0,
        this.paymentType
      )
    );
  }

  validateInformation() {
    this.isValidInformation = this.cartSvc.isPersonalInformationValid(
      this.orderType,
      this.personalInfo
    );
  }

  isFormValid(): boolean {
    return this.total && this.isValidInformation && this.paymentType != null;
  }
}
