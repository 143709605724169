import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Input() message: string = '';
  @Input() showEmail: boolean = false;
  @Input() showButton: boolean = false;

  constructor() {}

  onReload() {
    document.location.reload();
  }
}
