import { Component, Input } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { ProductDto } from '../../../../../../core/dtos';
import { Store } from '@ngxs/store';
import {
  CartChangeQuantityAction,
  CartGetAction,
} from '@store/client/cart/actions/cart.actions';

@Component({
  selector: 'app-card-cart',
  templateUrl: './card-cart.component.html',
  styleUrls: ['./card-cart.component.scss'],
})
export class CardCartComponent extends CoreComponent {
  @Input() key: string;
  @Input() product: ProductDto;

  constructor(private store: Store) {
    super();
  }

  onInit(): void {}

  changeQuantity(quantity: number): void {
    this.store.dispatch(new CartChangeQuantityAction(this.key, quantity));
  }
}
