import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { CartService } from '@core/https/cart.service';
import { PersonalInfo } from '@core/models/personal-info.model';
import { OrderType } from '@core/types/order-type.type';
import { Select, Store } from '@ngxs/store';
import { OrderTypeState } from '@store/client/order/order-type/order-type.state';
import { PersonalInfoGetAction } from '@store/client/personal-info/personal-info.actions';
import { PersonalInfoState } from '@store/client/personal-info/personal-info.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OrderTypeSelectorComponent } from '../../order-type-selector/order-type-selector.component';
import { PersonalInfoComponent } from '../../personal-info/personal-info.component';

@Component({
  selector: 'app-order-type-detail',
  templateUrl: './order-type-detail.component.html',
  styleUrls: ['./order-type-detail.component.scss'],
})
export class OrderTypeDetailComponent extends CoreComponent {
  @Select(PersonalInfoState.get) getPersonalInfo$: Observable<PersonalInfo>;
  @Select(OrderTypeState.getOrderType) getOrderType$: Observable<OrderType>;

  orderType: OrderType;
  personalInfo: PersonalInfo;
  isValidInformation: boolean;

  constructor(
    private orderTypeSelectorBottomSheet: MatBottomSheet,
    private personalInfoBottomSheet: MatBottomSheet,
    private store: Store,
    private cartSvc: CartService
  ) {
    super();
  }

  onInit(): void {
    this.getPersonalInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((personalInfo) => {
        if (!personalInfo) {
          this.store.dispatch(new PersonalInfoGetAction());
          return;
        }
        this.personalInfo = personalInfo;
        this.validateInformation();
      });

    this.getOrderType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orderType) => {
        this.orderType = orderType;
        this.validateInformation();
      });
  }

  openOrderTypeSelector(): void {
    this.orderTypeSelectorBottomSheet.open(OrderTypeSelectorComponent, {
      hasBackdrop: true,
      panelClass: 'bottom-sheet-no-padding',
    });
  }

  openPersonalInfo(): void {
    this.personalInfo = { ...this.personalInfo, orderType: this.orderType };
    this.personalInfoBottomSheet.open(PersonalInfoComponent, {
      hasBackdrop: true,
      panelClass: 'bottom-sheet-no-padding',
      data: { personalInfo: this.personalInfo },
    });
  }

  validateInformation() {
    this.isValidInformation = this.cartSvc.isPersonalInformationValid(
      this.orderType,
      this.personalInfo
    );
  }
}
