import { Injectable } from '@angular/core';
import { BusinessService } from '@core/https/business.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BusinessScheduler } from './business-scheduler.actions';
import { Business } from '@core/models/business.model';

export class BusinessSchedulerStateModel {
  public business: Business;
  public businessIsOpen: boolean;
  public message: string;
}

const defaults = {
  business: null,
  businessIsOpen: true,
  message: null,
};

@State<BusinessSchedulerStateModel>({
  name: 'businessScheduler',
  defaults,
})
@Injectable()
export class BusinessSchedulerState {
  constructor(private businessService: BusinessService) {}

  @Selector()
  public static getMessage({ message }): string {
    return message;
  }

  @Selector()
  public static getBusiness({ business }): Business {
    return business;
  }

  @Selector()
  public static businessIsOpen({ businessIsOpen }): boolean {
    return businessIsOpen;
  }

  @Action(BusinessScheduler.Get)
  get({ dispatch, patchState }: StateContext<BusinessSchedulerStateModel>) {
    return this.businessService.getBusiness().pipe(
      tap((business: Business) => patchState({ business })),
      tap((business: Business) =>
        dispatch(new BusinessScheduler.Success(business.isOpen ?? true))
      ),
      catchError((error) => {
        dispatch(new BusinessScheduler.Failure(error));
        return of();
      })
    );
  }

  @Action(BusinessScheduler.Success)
  successs(
    { patchState }: StateContext<BusinessSchedulerStateModel>,
    { businessIsOpen }: BusinessScheduler.Success
  ) {
    patchState({
      businessIsOpen,
      message: null,
    });
  }

  @Action(BusinessScheduler.Failure)
  failure(
    { patchState }: StateContext<BusinessSchedulerStateModel>,
    { message }: BusinessScheduler.Failure
  ) {
    patchState({
      message,
      businessIsOpen: true,
    });
  }
}
