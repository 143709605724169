export namespace ConfirmOrder {
  export class Confirm {
    static readonly type = '[ConfirmOrder] Confirm';
    constructor(public pointOfSale: boolean = false) {}
  }

  export class Success {
    static readonly type = '[ConfirmOrder] Success';
    constructor() {}
  }

  export class Failed {
    static readonly type = '[ConfirmOrder] Failed';
    constructor(public message: string) {}
  }
}
