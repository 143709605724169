import { Component } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { OrderProduct } from '@core/models/order-product.model';
import { Select, Store } from '@ngxs/store';
import {
  CartGetAction,
  CartGetTotalAction,
} from '@store/client/cart/actions/cart.actions';
import { CartState } from '@store/client/cart/states/cart.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OrderTypeSelectorComponent } from '../order-type-selector/order-type-selector.component';
import { ProductDto } from '@core/dtos';
import { ActivatedRoute, Params } from '@angular/router';
import { ConfirmOrder } from '@store/client/order/confirm-order/confirm-order.actions';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent extends CoreComponent {
  @Select(CartState.get) getProducts$: Observable<Map<string, ProductDto>>;
  @Select(CartState.getTotal) getTotal$: Observable<number>;

  products: Map<string, ProductDto>;
  total: number = 0;
  apiKey: string;

  constructor(
    private store: Store,
    private _bottomSheetRef: MatBottomSheetRef<CartComponent>,
    private orderTypeSelectorBottomSheet: MatBottomSheet,
    public activatedRoute: ActivatedRoute
  ) {
    super();
  }

  onInit(): void {
    this.store.dispatch(new CartGetAction());

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: Params) => {
        if (!params) {
          return;
        }
        const apiKey = params['apikey'];
        if (!apiKey) {
          return;
        }
        this.apiKey = apiKey;
      });

    this.getProducts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((products) => {
        if (!products) return;

        this.products = products;
      });

    this.store.dispatch(new CartGetTotalAction());

    this.getTotal$.pipe(takeUntil(this.unsubscribe$)).subscribe((total) => {
      if (total === null || total === undefined) return;
      this.total = total;
    });
  }

  dismiss(): void {
    this._bottomSheetRef.dismiss();
  }

  openOrderTypeSelector(): void {
    if (this.apiKey) {
      this.orderTypeSelectorBottomSheet.dismiss();
      this.store.dispatch(new ConfirmOrder.Confirm(true));
      return;
    }
    this.orderTypeSelectorBottomSheet.open(OrderTypeSelectorComponent, {
      hasBackdrop: true,
      panelClass: 'bottom-sheet-no-padding',
    });
  }
}
