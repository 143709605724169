import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-order-qr-bottom-sheet',
  templateUrl: './order-qr-bottom-sheet.component.html',
  styleUrls: ['./order-qr-bottom-sheet.component.scss'],
})
export class OrderQrBottomSheetComponent implements OnInit {
  id: string;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { id: string },
    private _bottomSheetRef: MatBottomSheetRef<OrderQrBottomSheetComponent>
  ) {
    this.id = data.id;
  }

  ngOnInit(): void {}

  dismiss() {
    this._bottomSheetRef.dismiss();
  }
}
