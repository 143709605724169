import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() message: string = 'Cargando ...';
  @Input() messages: string[] = [];

  currentMessageIndex: number = 0;
  constructor() {}

  ngOnInit(): void {
    if (this.messages.length > 0) {
      setInterval(() => {
        this.currentMessageIndex =
          (this.currentMessageIndex + 1) % this.messages.length;
      }, 2000);
    }
  }
}
