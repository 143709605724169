<div class="row">
  <div class="col-xs-12">
    <mat-list>
      <div mat-subheader>Ingresa tu información</div>
    </mat-list>
  </div>
  <form
    #mainForm
    class="col-xs-12"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-xs-12">
      <mat-form-field class="form__field" appearance="outline">
        <mat-label>Nombre *</mat-label>
        <mat-icon matSuffix>badge_outline</mat-icon>
        <input
          matInput
          type="text"
          formControlName="customerName"
          autocomplete="name"
        />
      </mat-form-field>
    </div>
    <div
      class="col-xs-12"
      *ngIf="
        personalInfo.orderType === 'DELIVERY' ||
        personalInfo.orderType === 'PICK_UP'
      "
    >
      <mat-form-field class="form__field" appearance="outline">
        <mat-label>WhatsApp *</mat-label>
        <input
          matInput
          #inputPhone
          formControlName="phone"
          type="number"
          onKeyPress="if(this.value.length===10) return false;"
          autocomplete="off"
        />
        <mat-icon matSuffix>chat_bubble_outline</mat-icon>
        <mat-hint align="end">{{ inputPhone.value?.length || 0 }}/10</mat-hint>
      </mat-form-field>
    </div>
    <ng-container *ngIf="personalInfo.orderType === 'DELIVERY'">
      <div class="col-xs-12">
        <mat-form-field class="form__field" appearance="outline">
          <mat-label>Código Postal *</mat-label>
          <input matInput type="number" formControlName="zip" />
        </mat-form-field>
      </div>
      <div class="col-xs-12">
        <mat-form-field class="form__field" appearance="outline">
          <mat-label>Colonia *</mat-label>
          <input matInput type="text" formControlName="neighborhood" />
        </mat-form-field>
      </div>
      <div class="col-xs-12">
        <mat-form-field class="form__field" appearance="outline">
          <mat-label>Calle y Número *</mat-label>
          <input matInput type="text" formControlName="street" />
        </mat-form-field>
      </div>
      <div class="col-xs-12">
        <mat-form-field class="form__field" appearance="outline">
          <mat-label>Referencias (opcional)</mat-label>
          <input matInput type="text" formControlName="reference" />
        </mat-form-field>
      </div>
    </ng-container>
    <div class="col-xs-12 start-xs">
      <span class="privacity"
        >Al hacer clic en "Guardar", aceptas nuestras Condiciones y la
        <span (click)="openPrivacyPolicy()">Política de privacidad</span> de
        NotMenu.</span
      >
    </div>
    <div class="col-xs-12">
      <button mat-raised-button type="submit" [disabled]="!formGroup.valid">
        Guardar
      </button>
    </div>
  </form>
</div>
