import { Injectable } from '@angular/core';
import { MenuService } from '@core/https/menu.service';
import { Menu } from '@core/models/menu.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetMenuAction,
  GetMenuFailedAction,
  GetMenuInitializedAction,
  GetMenuSuccessAction,
} from '../actions/get-menu.actions';

export class GetMenuStateModel {
  public loading: boolean;
  public menu: Menu;
  public message: string;
}

const defaults = {
  loading: true,
  menu: null,
  message: null,
};

@State<GetMenuStateModel>({
  name: 'getMenu',
  defaults,
})
@Injectable()
export class GetMenuState {
  constructor(private menuSvc: MenuService) {}

  @Selector()
  public static getting({ loading }) {
    return loading;
  }

  @Selector()
  public static getMenu({ menu }) {
    return menu;
  }

  @Selector()
  public static getError({ message }) {
    return message;
  }

  @Action(GetMenuAction)
  get(ctx: StateContext<GetMenuStateModel>, { idMenu }: GetMenuAction) {
    this.getting(ctx);

    return this.menuSvc.get(idMenu).pipe(
      tap((menu) => {
        if (!menu)
          ctx.dispatch(
            new GetMenuFailedAction(
              'Menú no encontrado, por favor verifica la dirección de internet e intenta de nuevo.'
            )
          );
        else ctx.dispatch(new GetMenuSuccessAction(menu));
      }),
      catchError((error) => {
        ctx.dispatch(new GetMenuFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(GetMenuFailedAction)
  fail(ctx: StateContext<GetMenuStateModel>, { message }: GetMenuFailedAction) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      menu: null,
      message: message,
    });
  }

  @Action(GetMenuInitializedAction)
  initialize(ctx: StateContext<GetMenuStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: true,
      menu: null,
      message: null,
    });
  }

  @Action(GetMenuSuccessAction)
  success(
    ctx: StateContext<GetMenuStateModel>,
    { menu }: GetMenuSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      menu: menu,
    });
  }

  private getting(ctx: StateContext<GetMenuStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
