import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { PaymentTypeActions } from './payment-type.actions';
import { PaymentType } from '@core/types/payment.type';

export class PaymentTypeStateModel {
  public paymentType: PaymentType;
}

const defaults = {
  paymentType: null,
};

@State<PaymentTypeStateModel>({
  name: 'paymentType',
  defaults,
})
@Injectable()
export class PaymentTypeState {
  @Selector()
  static getPaymentType({ paymentType }): PaymentType {
    return paymentType;
  }

  @Action(PaymentTypeActions.Set)
  add(
    { patchState }: StateContext<PaymentTypeStateModel>,
    { paymentType }: PaymentTypeActions.Set
  ) {
    patchState({ paymentType });
  }
}
