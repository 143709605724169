import { Pipe, PipeTransform } from '@angular/core';
import { OrderHelper } from '@core/helpers/order.helper';
import { OrderType } from '@core/types/order-type.type';

@Pipe({
  name: 'orderType',
})
export class OrderTypePipe implements PipeTransform {
  constructor(private orderHelper: OrderHelper) {}

  transform(orderType: OrderType): string {
    return this.orderHelper.getName(orderType);
  }
}
