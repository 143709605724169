<app-header [menu]="menu"></app-header>
<div class="information">
  <div class="row">
    <div class="col-xs-12">
      <h2>Pedido envíado con éxito</h2>
      <h2>tu folio es</h2>
      <h4>{{ lastOrder.orderId | orderId }}</h4>
    </div>
    <div class="col-xs-12">
      <button
        class="container-button"
        mat-raised-button
        (click)="sendNotification()"
      >
        <mat-icon>chat_bubble_outline</mat-icon>
        Avisar al negocio
      </button>
    </div>
    <div class="col-xs-12 back-to-menu" *ngIf="showGoToMenu">
      <button mat-raised-button class="mat-raised-button" (click)="goToMenu()">
        <mat-icon>arrow_back_ios_new</mat-icon>
        Regresar al menú
      </button>
    </div>
  </div>
</div>
