import { Pipe, PipeTransform } from '@angular/core';
import { Extra } from '@core/models/extra.model';

@Pipe({
  name: 'sortExtras',
})
export class SortExtrasPipe implements PipeTransform {
  transform(extras: Extra[], sort: string[]): Extra[] {
    const sortedExtras: Extra[] = [];
    for (const extraId of sort) {
      const extra: Extra = extras.find((e) => e.extraId === extraId);
      if (extra) {
        sortedExtras.push(extra);
      }
    }
    return sortedExtras;
  }
}
