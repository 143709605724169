<div class="row cart-title-stick">
  <span class="cart-title col-xs-12">Mis pedidos</span>
</div>
<div class="products-detail">
  <app-my-products-detail *ngFor="let order of orders" [order]="order"></app-my-products-detail>
</div>
<footer class="col-bottom">
  <div class="button-back col-xs-12">
      <button mat-raised-button (click)="goToMenu()">
        <mat-icon>arrow_back_ios_new</mat-icon>
              Regresar al menú
      </button>
  </div>
</footer>
