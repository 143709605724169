import { Component, OnInit } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { Order } from '@core/models/order.model';
import { Select } from '@ngxs/store';
import { CreateOrderState } from '@store/client/order/create-order/create-order.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetMenuState } from '@store/client/menu/states/get-menu.state';
import { Menu } from '@core/models/menu.model';
import { OrderType } from '@core/types/order-type.type';
import { OrderTypeState } from '@store/client/order/order-type/order-type.state';
import { CartState } from '@store/client/cart/states/cart.state';
import { OrderDto, ProductDto } from '@core/dtos';
import { OrderReaderState } from '@store/client/order/order-reader/order-reader.state';

@Component({
  selector: 'app-products-detail',
  templateUrl: './products-detail.component.html',
  styleUrls: ['./products-detail.component.scss'],
})
export class ProductsDetailComponent extends CoreComponent {
  @Select(GetMenuState.getMenu) getMenu$: Observable<Menu>;
  @Select(CreateOrderState.getOrder) getOrder$: Observable<Order>;
  @Select(OrderReaderState.currentOrder) currentOrder$: Observable<OrderDto>;
  @Select(OrderTypeState.getOrderType) getOrderType$: Observable<OrderType>;

  @Select(CartState.get) getProducts$: Observable<Map<string, ProductDto>>;
  @Select(CartState.getTotal) getTotal$: Observable<number>;

  constructor() {
    super();
  }

  loading: boolean = true;
  priceWithoutFee: boolean = true;
  order: OrderDto;
  menu: Menu;
  products: ProductDto[];
  total: number;
  orderType: OrderType;

  onInit(): void {
    this.getMenu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu) => {
      if (!menu) return;

      this.menu = menu;
    });

    this.getOrderType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orderType) => {
        if (!orderType) return;

        this.orderType = orderType;
        if (this.orderType === 'DELIVERY') {
          this.total += this.menu.deliveryFee ?? 0;
          this.priceWithoutFee = false;
        } else {
          if (!this.priceWithoutFee) {
            this.total -= this.menu.deliveryFee ?? 0;
            this.priceWithoutFee = true;
          }
        }
      });

    this.currentOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((order) => {
      if (!order) return;

      this.order = order;
      this.loading = false;
    });

    this.getProducts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((products: Map<string, ProductDto>) => {
        if (!products) return;

        this.products = Array.from<ProductDto>(products.values());
        this.loading = false;
      });

    this.getTotal$.pipe(takeUntil(this.unsubscribe$)).subscribe((total) => {
      if (this.orderType) {
        this.total = total;
        if (this.orderType === 'DELIVERY') {
          this.total += this.menu.deliveryFee ?? 0;
          this.priceWithoutFee = false;
        }
      }
    });
  }
}
