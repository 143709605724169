import { Injectable } from '@angular/core';
import { OrderService } from '@core/https/order.service';
import { OrderRequested } from '@core/models/order-requested.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { OrderReader } from './order-reader.actions';
import { OrderDto } from '@core/dtos';
import { OrderRequest } from '@core/models/order-request.model';

export class OrderReaderStateModel {
  public orders: Map<string, OrderRequested>;
  order: OrderDto;
  lastOrder: OrderRequest;
}

const defaults = {
  orders: null,
  order: null,
  lastOrder: null,
};

@State<OrderReaderStateModel>({
  name: 'orderReader',
  defaults,
})
@Injectable()
export class OrderReaderState {
  constructor(private orderSvc: OrderService) {}

  @Selector()
  public static get({ orders }) {
    return orders;
  }

  @Selector()
  public static currentOrder(state: OrderReaderStateModel) {
    return state.order;
  }

  @Selector()
  public static lastOrder(state: OrderReaderStateModel) {
    return state.lastOrder;
  }

  @Action(OrderReader.FromRepository)
  get(ctx: StateContext<OrderReaderStateModel>) {
    const orders: Map<string, OrderRequested> =
      this.orderSvc.getOrdersRequested();
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      orders: orders,
    });
  }

  @Action(OrderReader.CurrentOrder)
  getCurrentOrder({ patchState }: StateContext<OrderReaderStateModel>) {
    const order: OrderDto = this.orderSvc.getCurrentOrder();
    patchState({
      order,
    });
  }

  @Action(OrderReader.LastOrder)
  getLastOrder({ patchState }: StateContext<OrderReaderStateModel>) {
    const lastOrder: OrderRequest = this.orderSvc.getLastOrder();
    patchState({
      lastOrder,
    });
  }
}
