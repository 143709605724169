import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GetMenuAction } from '@store/client/menu/actions/get-menu.actions';
import {
  LoginAction,
  LoginInitializedAction,
} from '@store/security/actions/login.actions';
import { LoginState } from '@store/security/states/login.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class LoginComponent implements OnInit, OnDestroy {
  @Select(LoginState.getError) errorLogin$: Observable<string>;
  @Select(LoginState.getIdMenu) idMenu$: Observable<string>;
  @Select(LoginState.logging) logging$: Observable<boolean>;

  error: boolean;
  isMobile: boolean;
  loading: boolean = true;
  message: string;
  paramKey: string;
  status: number = 500;
  unsubscribe$ = new Subject<void>();

  constructor(
    public activatedRoute: ActivatedRoute,
    public ddsvc: DeviceDetectorService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoginInitializedAction());
    this.isMobile = this.ddsvc.isMobile();

    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: Params) => {
        this.paramKey = params?.key;
        if (this.paramKey) {
          this.store.dispatch(new LoginAction(this.paramKey));
        }
      });

    this.idMenu$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((idMenu: string) => {
        if (idMenu) {
          this.store.dispatch(new GetMenuAction(idMenu));
        }
      });

    this.errorLogin$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorLogin: string) => {
        if (errorLogin) {
          this.status = 404;
          this.error = true;
        }
      });

    this.logging$.pipe(takeUntil(this.unsubscribe$)).subscribe((logging) => {
      this.loading = logging;
    });

    this.onInit();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  onDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  abstract onInit(): void;
}
