<mat-list-item>
  <div mat-line class="row main-div">
    <span class="col-xs-1 quantity">{{ product.quantity }}</span>
    <span class="col-xs-8 product-name">{{ product.name }}</span>
    <span class="col-xs-3 section-price">
      <mat-icon class="promotion" *ngIf="product.promotion">discount</mat-icon>
      <span class="price">{{ product | totalProductPrice | currency }} </span>
    </span>
  </div>
  <div mat-line *ngIf="product.extras.length > 0">
    <span class="extras">{{ product.extras | describeExtras }}</span>
  </div>
</mat-list-item>
