import { Component, Input } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { ProductDto } from '@core/dtos';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent extends CoreComponent {
  @Input() product: ProductDto;

  constructor() {
    super();
  }

  onInit(): void {}
}
