import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CoreComponent } from '@client/core/core.component';
import { Order } from '@core/models/order.model';
import { Select, Store } from '@ngxs/store';
import { OrderWhatsapp } from '@store/client/order/order-whatsapp/order-whatsapp.actions';
import { RequestOrderAction } from '@store/client/order/request-order/request-order.actions';
import { RequestOrderState } from '@store/client/order/request-order/request-order.state';
import { ConsolidatePayment } from '@store/client/payment/consolidate-payment/consolidate-payment.actions';
import {
  ConsolidatePaymentState,
  ConsolidatePaymentStateModel,
} from '@store/client/payment/consolidate-payment/consolidate-payment.state';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent extends CoreComponent {
  @Select(ConsolidatePaymentState.isSuccess) isSuccess$: Observable<boolean>;
  @Select(ConsolidatePaymentState.isFailed)
  isFailed$: Observable<ConsolidatePaymentStateModel>;
  @Select(RequestOrderState.get) getOrder$: Observable<Order>;

  loading: boolean = true;
  message: string;
  orderId: string;
  reties: number;

  constructor(
    public activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    super();
  }

  onInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: Params) => {
        this.orderId = params?.orderId;
        this.store.dispatch(new ConsolidatePayment.Add(this.orderId));
      });

    this.isSuccess$.pipe(takeUntil(this.unsubscribe$)).subscribe((paid) => {
      if (!paid) return;
      this.message = 'Pago consolidado con éxito';
      this.store.dispatch(new RequestOrderAction(null, null, null));
    });

    this.isFailed$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (!state?.error) return;

      this.loading = false;
      this.message = state.error.message;
      ++this.reties;
    });

    this._subscribeNavigateToRequested();
  }

  private _subscribeNavigateToRequested(): void {
    this.getOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((order) => {
      if (!order) return;

      this.router
        .navigate(['order-requested'])
        .then((nav) => {
          console.log('navigated to order-requested');
        })
        .catch((error) => {
          console.error('Error trying to navigate to order-requested', error);
        })
        .finally(() => {
          this.store.dispatch(new OrderWhatsapp.Inital());
        });
    });
  }
}
