import { OrderDto } from '@core/dtos';
import { OrderType } from '@core/types/order-type.type';
import { PaymentType } from '@core/types/payment.type';

export class RequestOrderAction {
  static readonly type = '[RequestOrder]';
  constructor(
    public orderType: OrderType,
    public deliveryFee: number,
    public paymentType: PaymentType
  ) {}
}

export class RequestOrderSuccessAction {
  static readonly type = '[RequestOrder] Success';
  constructor(public order: OrderDto) {}
}

export class RequestOrderOnlinePaymentAction {
  static readonly type = '[RequestOrder] Online Payment';
  constructor(public url: string) {}
}

export class RequestOrderFailedAction {
  static readonly type = '[RequestOrder] Failed';
  constructor(public message: string) {}
}
