import { PersonalInfo } from '@core/models/personal-info.model';

export class PersonalInfoGetAction {
  static readonly type = '[PersonalInfo] Get';
  constructor() {}
}

export class PersonalInfoSetAction {
  static readonly type = '[PersonalInfo] Set';
  constructor(public personalInfo: PersonalInfo) {}
}
