import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientModule } from '@client/client.module';
import { CoreModule } from '@core/core.module';
import { StateModule } from '@store/state.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BackButtonDisableModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    ClientModule,
    CoreModule,
    HttpClientModule,
    StateModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
