import { Injectable } from '@angular/core';
import { OrderService } from '@core/https/order.service';
import { Order } from '@core/models/order.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';

import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  CreateOrderAction,
  CreateOrderFailedAction,
  CreateOrderSuccessAction,
} from './create-order.actions';
import { OrderDto } from '@core/dtos';

export class CreateOrderStateModel {
  public order: OrderDto;
  public message: string;
  public total: number;
}

const defaults = {
  order: null,
  message: null,
  total: null,
};

@State<CreateOrderStateModel>({
  name: 'createOrder',
  defaults,
})
@Injectable()
export class CreateOrderState {
  constructor(private orderSvc: OrderService) {}

  @Selector()
  public static getOrder({ order }) {
    return order;
  }

  @Selector()
  public static getTotal({ total }) {
    return total;
  }

  @Selector()
  public static getMessage({ message }) {
    return message;
  }

  @Action(CreateOrderAction)
  add(ctx: StateContext<CreateOrderStateModel>) {
    let order: OrderDto;
    try {
      order = this.orderSvc.getOrderFromCart();
    } catch (error) {
      ctx.dispatch(new CreateOrderFailedAction(error));
      return of();
    }

    return this.orderSvc.create().pipe(
      tap((order) => {
        ctx.dispatch(new CreateOrderSuccessAction(order));
      }),
      catchError((error) => {
        ctx.dispatch(new CreateOrderFailedAction(error));
        return of();
      })
    );
  }

  @Action(CreateOrderSuccessAction)
  success(
    ctx: StateContext<CreateOrderStateModel>,
    { order }: CreateOrderSuccessAction
  ) {
    const state = ctx.getState();
    const total: number = order.products.reduce(
      (prev, current) => (prev += current.price),
      0
    );
    ctx.patchState({
      ...state,
      order: order,
      total: total,
      message: null,
    });
  }

  @Action(CreateOrderFailedAction)
  fail(
    ctx: StateContext<CreateOrderStateModel>,
    { message }: CreateOrderFailedAction
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      order: null,
      total: null,
      message: message,
    });
  }
}
