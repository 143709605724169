import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { CallApiService } from './call-api.service';
import { LocalDataService } from './local-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFacadeService {
  constructor(private casvc: CallApiService, private ldsvc: LocalDataService) {}

  get<T>({
    segment,
    storageOptions,
    isOldGateway = true,
  }: {
    segment: string;
    storageOptions?: { search?: boolean; save?: boolean; key?: string };
    isOldGateway?: boolean;
  }): Observable<T> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._searchAndGet<T>({
        segment,
        storageOptions: _storageOptions,
        isOldGateway,
      });
    }

    return this._get<T>({
      segment,
      storageOptions: _storageOptions,
      isOldGateway,
    });
  }

  getMany<T>(
    segment: string,
    storageOptions?: { search?: boolean; save?: boolean; key?: string }
  ): Observable<T[]> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._searchAndGetMany<T>(segment, _storageOptions);
    }

    return this._getMany<T>(segment, _storageOptions);
  }

  post<T>({
    segment,
    data,
    storageOptions,
  }: {
    segment: string;
    data: T;
    storageOptions?: { save?: boolean; key?: string };
  }): Observable<T> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    return this.casvc
      .post<T>({ urlSegment: segment, data, isOldGateway: false })
      .pipe(
        tap((dataApi: T) => {
          if (_storageOptions.save)
            this.ldsvc.set<T>(_storageOptions.key, dataApi);
        }),
        map((dataApi: T) => dataApi),
        catchError((error) => throwError(error))
      );
  }

  put<T>(
    segment: string,
    data: T,
    storageOptions?: { search?: boolean; save?: boolean; key?: string }
  ): Observable<T> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._compareAndPut<T>(segment, data, _storageOptions);
    }

    return this._put<T>(segment, data, _storageOptions);
  }

  private _compareAndPut<T>(
    segment: string,
    data: T,
    storageOptions: { save: boolean; key: string }
  ): Observable<T> {
    return this.ldsvc.equalsObs<T>(storageOptions.key, data).pipe(
      switchMap((isEqual) =>
        iif(
          () => isEqual,
          of(data),
          this._put<T>(segment, data, storageOptions)
        )
      ),
      catchError((error) => throwError(error))
    );
  }

  private _get<T>({
    segment,
    storageOptions,
    isOldGateway = true,
  }: {
    segment: string;
    storageOptions: { save: boolean; key: string; search?: boolean };
    isOldGateway?: boolean;
  }): Observable<T> {
    return this.casvc
      .get<T>({
        urlSegment: segment,
        isOldGateway,
      })
      .pipe(
        tap((data) => {
          if (storageOptions.save) {
            this.ldsvc.set<T>(storageOptions.key, data);
          }
        }),
        map((data) => data),
        catchError((error) => throwError(error))
      );
  }

  private _getMany<T>(
    segment: string,
    storageOptions: { save: boolean; key: string }
  ): Observable<T[]> {
    return this.casvc.getMany<T>(segment).pipe(
      tap((data) => {
        if (storageOptions.save)
          this.ldsvc.setMany<T>(storageOptions.key, data);
      }),
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  private _getStorageOptions(
    segment: string,
    storageOptions?: {
      search?: boolean;
      save?: boolean;
      key?: string;
    }
  ): any {
    if (!storageOptions) {
      return { search: false, save: false, key: null };
    }

    return {
      search: storageOptions.search ?? false,
      save: storageOptions.save ?? false,
      key: storageOptions.key ?? segment,
    };
  }

  private _put<T>(
    segment: string,
    data: T,
    storageOptions: { save: boolean; key: string }
  ): Observable<T> {
    return this.casvc.put<T>(segment, data).pipe(
      tap((dataApi: T) => {
        if (storageOptions.save) this.ldsvc.set<T>(storageOptions.key, dataApi);
      }),
      map((dataApi: T) => dataApi),
      catchError((error) => throwError(error))
    );
  }

  private _searchAndGet<T>({
    segment,
    storageOptions,
    isOldGateway = true,
  }: {
    segment: string;
    storageOptions: { save: boolean; key: string };
    isOldGateway: boolean;
  }): Observable<T> {
    return this.ldsvc.getObs<T>(storageOptions.key).pipe(
      concatMap((data) => {
        return iif(
          () => data !== undefined,
          of(data),
          this._get<T>({ segment, storageOptions, isOldGateway })
        );
      }),
      catchError((error) => throwError(error))
    );
  }

  private _searchAndGetMany<T>(
    segment: string,
    storageOptions: { save: boolean; key: string }
  ): Observable<T[]> {
    return this.ldsvc.getManyObs<T>(storageOptions.key).pipe(
      concatMap((data) => {
        return iif(
          () => data !== undefined,
          of(data),
          this._getMany<T>(segment, storageOptions)
        );
      }),
      catchError((error) => throwError(error))
    );
  }
}
