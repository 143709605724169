import { Injectable } from '@angular/core';
import { ProductService } from '@core/https/product.service';
import { Product } from '@core/models/product.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetProductAction,
  GetProductFailedAction,
  GetProductSuccessAction,
} from '../actions/get-product.actions';

export class GetProductStateModel {
  public product: Product;
}

const defaults = {
  product: null,
};

@State<GetProductStateModel>({
  name: 'getProduct',
  defaults,
})
@Injectable()
export class GetProductState {
  constructor(private productSvc: ProductService) {}

  @Selector()
  public static getProduct({ product }) {
    return product;
  }

  @Action(GetProductAction)
  get(
    ctx: StateContext<GetProductStateModel>,
    { productId }: GetProductAction
  ) {
    return this.productSvc.get(productId).pipe(
      tap((product) => {
        ctx.dispatch(new GetProductSuccessAction(product));
      }),
      catchError(() => {
        ctx.dispatch(new GetProductFailedAction());
        return of();
      })
    );
  }

  @Action(GetProductSuccessAction)
  success(
    ctx: StateContext<GetProductSuccessAction>,
    { product }: GetProductSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      product: product,
    });
  }

  @Action(GetProductFailedAction)
  fail(ctx: StateContext<GetProductSuccessAction>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      product: null,
    });
  }
}
