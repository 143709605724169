<footer class="col-bottom">
  <div class="row">
    <div class="col-xs-12 container-button">
      <button
        mat-raised-button
        (click)="requestOrder()"
        [disabled]="!isFormValid()"
      >
        Solicitar pedido {{ total ? "por " + (total | currency) : "" }}
      </button>
    </div>
  </div>
</footer>
