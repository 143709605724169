import { Injectable } from '@angular/core';
import { OrderType } from '@core/types/order-type.type';

@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  getName(orderType: OrderType): string {
    switch (orderType) {
      case 'DELIVERY':
        return 'Envío a domicilio';
      case 'HERE':
        return 'Ordenar aquí';
      case 'PICK_UP':
        return 'Para recoger';
      default:
        return 'Error';
    }
  }
}
