import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreComponent } from '@client/core/core.component';
import { CustomerInfo } from '@core/models/customer-info.model';
import { PersonalInfo } from '@core/models/personal-info.model';
import { Select, Store } from '@ngxs/store';
import {
  CustomerAddAction,
  CustomerInitAction,
} from '@store/client/customer/customer.actions';
import { CustomerState } from '@store/client/customer/customer.state';
import { PersonalInfoGetAction } from '@store/client/personal-info/personal-info.actions';
import { PersonalInfoState } from '@store/client/personal-info/personal-info.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrivacyPolicyDialogComponent } from '../privacy-policy-dialog/privacy-policy-dialog.component';

@Component({
  selector: 'app-customer-info-bottom-sheet',
  templateUrl: './customer-info-bottom-sheet.component.html',
  styleUrls: ['./customer-info-bottom-sheet.component.scss'],
})
export class CustomerInfoBottomSheetComponent extends CoreComponent {
  @Select(PersonalInfoState.get) getPersonalInfo$: Observable<PersonalInfo>;
  @Select(CustomerState.getMessage) getMessage$: Observable<string>;

  personalInfo: PersonalInfo;
  formGroup: FormGroup;
  today: Date = new Date();

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<CustomerInfoBottomSheetComponent>,
    private store: Store,
    public privacyPolicyDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    super();
  }

  onInit(): void {
    this.store.dispatch(new CustomerInitAction());

    this.getPersonalInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((personalInfo) => {
        if (!personalInfo) {
          this.store.dispatch(new PersonalInfoGetAction());
          return;
        }
        this.personalInfo = personalInfo;
        this.initializeFormGroup();
      });

    this.getMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message: string) => {
        if (!message) return;
        console.error('getMessage$', message);
        this.showSnackBar(
          'Sucedió un error inesperado por favor actualiza la página e intenta de nuevo.',
          6000
        );
        this.dismiss();
      });
    this.initializeFormGroup();
  }

  onSubmit() {
    const customer: CustomerInfo = { ...this.formGroup.value };
    customer.phone = customer.phone + '';
    this.store.dispatch(new CustomerAddAction(customer));
    this.showSnackBar('Subcripción en proceso...');
    this.dismiss();
  }

  dismiss() {
    this._bottomSheetRef.dismiss();
  }

  openPrivacyPolicy() {
    this.privacyPolicyDialog.open(PrivacyPolicyDialogComponent);
  }

  private initializeFormGroup() {
    this.formGroup = new FormGroup({
      name: new FormControl(this.personalInfo?.customerName, [
        Validators.required,
        Validators.minLength(3),
      ]),
      phone: new FormControl(this.personalInfo?.phone, [
        Validators.required,
        Validators.min(1000000000),
        Validators.max(9999999999),
      ]),
      birthday: new FormControl(this.personalInfo?.birthday, [
        Validators.required,
      ]),
      email: new FormControl(this.personalInfo?.email, [Validators.email]),
    });
  }

  private showSnackBar(message: string, duration: number = 3000) {
    this._snackBar.open(message, 'Cerrar', { duration });
  }
}
