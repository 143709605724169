import { OrderDto } from '@core/dtos';
import { Order } from '@core/models/order.model';

export class CreateOrderAction {
  static readonly type = '[CreateOrder]';
  constructor() {}
}

export class CreateOrderSuccessAction {
  static readonly type = '[CreateOrder] Success';
  constructor(public order: OrderDto) {}
}

export class CreateOrderFailedAction {
  static readonly type = '[CreateOrder] Failed';
  constructor(public message: string) {}
}
