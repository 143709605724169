export namespace OrderReader {
  export class FromRepository {
    static readonly type = '[OrderReader] FromRepository';
    constructor() {}
  }

  export class CurrentOrder {
    static readonly type = '[OrderReader] CurrentOrder';
    constructor() {}
  }

  export class LastOrder {
    static readonly type = '[OrderReader] LastOrder';
    constructor() {}
  }
}
