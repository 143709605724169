import { formatCurrency } from '@angular/common';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { CoreComponent } from '@client/core/core.component';
import { OrderIdPipe } from '@client/pipes/order-id.pipe';
import { ExtraDto, OptionDto, OrderDto, ProductDto } from '@core/dtos';
import { OrderHelper } from '@core/helpers/order.helper';
import { CartService } from '@core/https/cart.service';
import { Menu } from '@core/models/menu.model';
import { OrderRequest } from '@core/models/order-request.model';
import { OrderRequested } from '@core/models/order-requested.model';
import { LocalDataService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { GetMenuAction } from '@store/client/menu/actions/get-menu.actions';
import { GetMenuState } from '@store/client/menu/states/get-menu.state';
import { OrderReader } from '@store/client/order/order-reader/order-reader.actions';
import { OrderReaderState } from '@store/client/order/order-reader/order-reader.state';
import { OrderWhatsapp } from '@store/client/order/order-whatsapp/order-whatsapp.actions';
import { OrderWhatsappState } from '@store/client/order/order-whatsapp/order-whatsapp.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-requested',
  templateUrl: './order-requested.component.html',
  styleUrls: ['./order-requested.component.scss'],
})
export class OrderRequestedComponent extends CoreComponent {
  @Select(GetMenuState.getMenu) menu$: Observable<Menu>;
  @Select(OrderReaderState.lastOrder) lastOrder$: Observable<OrderRequest>;
  @Select(OrderWhatsappState.getOrderWhatsapp)
  getOrderWhatsapp$: Observable<boolean>;
  @Select(OrderReaderState.get) orders$: Observable<
    Map<string, OrderRequested>
  >;

  showGoToMenu: boolean;
  menu: Menu;
  lastOrder: OrderRequest;
  order: OrderDto;

  constructor(
    private localDataSvc: LocalDataService,
    private store: Store,
    @Inject(LOCALE_ID) private locale: string,
    private cartService: CartService
  ) {
    super();
  }

  onInit(): void {
    this.menu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu: Menu) => {
      if (!menu) return;

      this.menu = menu;
    });
    if (!this.menu) this.store.dispatch(new GetMenuAction());

    this.lastOrder$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((lastOrder) => {
        if (!lastOrder) return;

        this.lastOrder = lastOrder;
        this.store.dispatch(new OrderReader.FromRepository());
      });
    if (!this.lastOrder) this.store.dispatch(new OrderReader.LastOrder());

    this.getOrderWhatsapp$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((send) => {
        this.showGoToMenu = send;
      });
    this.store.dispatch(new OrderWhatsapp.Get());

    this.orders$.pipe(takeUntil(this.unsubscribe$)).subscribe((orders) => {
      if (!orders) return;

      this.order = orders.get(this.lastOrder.orderId).order;
    });
  }

  sendNotification() {
    const orderId = new OrderIdPipe().transform(this.lastOrder.orderId);
    this.store.dispatch(new OrderWhatsapp.Send());
    let texturl: string = `https://api.whatsapp.com/send?phone=521${this.menu.phoneForOrders}&text=`;
    let text = `👋 Hola, ${this.menu.name.trim()}.${this.buildDetail()}`;
    text += `%0A%0AEl número de pedido es *${orderId}*, espero tu confirmación.%0A%0A🙌 ¡Muchas gracias!%0A%0A_Editar este mensaje invalida su autenticidad._%0A%0A_Este mensaje ha sido creado a través de_ 🚀 *_NotMenu._*`;
    texturl += this.replaceSpecialCharacters(text);
    document.location.href = texturl;
  }

  private buildDetail(): string {
    let text: string = `%0A%0AMi nombre es *${this._toCapitaleEvenLetter(
      this.lastOrder.customerName
    )}*, te comparto el detalle de mi pedido.`;
    text += '%0A%0A' + (this.lastOrder.type === 'DELIVERY' ? '🚚' : '🏪');
    text += ` *Tipo de pedido:* ${new OrderHelper()
      .getName(this.lastOrder.type)
      .trim()}`;
    if (this.lastOrder.type === 'DELIVERY') {
      text += `%0A- Calle: ${this.lastOrder.address.street}%0A`;
      text += `- Colonia: ${this.lastOrder.address.neighborhood}, C.P. ${this.lastOrder.address.zip}%0A`;
      if (this.lastOrder.address.references) {
        text += `- Referencia: ${this.lastOrder.address.references}`;
      }
    }

    text += `%0A%0A🍔 *Descripción del pedido:*`;
    for (let i: number = 0; i < this.order.products.length; i++) {
      let product: ProductDto = this.order.products[i];
      text += `%0A${product.quantity}  ${product.name}  ${this._toCurrency(
        this.cartService.getTotalProduct(product)
      )}`;
      if (product.extras) {
        for (let j: number = 0; j < product.extras.length; j++) {
          let extra: ExtraDto = product.extras[j];
          text += `%0A   * _${extra.name}_`;
          if (extra.type === 'OPEN') {
            text += `%0A      - ${extra.value}`;
          } else {
            if (extra.options) {
              for (let o: number = 0; o < extra.options.length; o++) {
                let option: OptionDto = extra.options[o];
                text += `%0A      - ${option.name}`;
              }
            }
          }
        }
      }
    }

    let total = this.order.products.reduce(
      (acc, prod) => (acc += this.cartService.getTotalProduct(prod)),
      0
    );
    if (this.lastOrder.type === 'DELIVERY' && this.lastOrder.deliveryFee > 0) {
      total += this.lastOrder.deliveryFee;
      text += `%0ACosto de envío: ${this._toCurrency(
        this.lastOrder.deliveryFee
      )}`;
    }

    text += `%0A%0A💵 *Total a pagar: ${this._toCurrency(total)}*`;
    return text;
  }

  goToMenu() {
    const key: string = this.localDataSvc.get<string>('__ck');
    if (key) {
      window.location.href = `/${key}`;
    }
  }

  private replaceSpecialCharacters(value: string): string {
    return value
      .split('&')
      .join('%26')
      .split('?')
      .join('%3F')
      .split('@')
      .join('%40')
      .split('#')
      .join('%23')
      .split(' ')
      .join('%20')
      .split('-')
      .join('%2D')
      .split('¡')
      .join('%C2%A1')
      .split('!')
      .join('%21')
      .split(',')
      .join('%2C')
      .split('.')
      .join('%2E')
      .split(':')
      .join('%3A')
      .split('é')
      .join('%C3%A9')
      .split('í')
      .join('%C3%AD')
      .split('ó')
      .join('%C3%B3')
      .split('ú')
      .join('%C3%BA')
      .split('*')
      .join('%2A')
      .split('+')
      .join('%2B')
      .split('🚚')
      .join('%F0%9F%9A%9A')
      .split('🏪')
      .join('%F0%9F%8F%AA')
      .split('👋')
      .join('%F0%9F%91%8B')
      .split('🍔')
      .join('%F0%9F%8D%94')
      .split('🙌')
      .join('%F0%9F%99%8C')
      .split('🚀')
      .join('%F0%9F%9A%80')
      .split('💵')
      .join('%F0%9F%92%B5');
  }

  private _toCapitaleEvenLetter(value: string): string {
    return value
      .toLowerCase()
      .split(' ')
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
      .join(' ');
  }

  private _toCurrency(value: number): string {
    return formatCurrency(value, this.locale, '$');
  }
}
