<ng-container
  [ngTemplateOutlet]="
    confirmFromPointOfSaleLoading
      ? pageConfirmFromPointOfSaleLoading
      : !loading && !addProductLoading
      ? page
      : loading
      ? pageLoading
      : pageCartAddLoading
  "
></ng-container>

<ng-template #pageConfirmFromPointOfSaleLoading>
  <app-loading message="Guardando pedido..."></app-loading>
</ng-template>

<ng-template #pageLoading>
  <app-loading [messages]="messages" message="Cargando menú..."></app-loading>
</ng-template>

<ng-template #pageCartAddLoading>
  <app-loading message="Agregando al carrito"></app-loading>
</ng-template>

<ng-template #page>
  <ng-container *ngIf="!error; then pageMenu; else pageError"></ng-container>
</ng-template>

<ng-template #pageMenu>
  <div class="base-container">
    <app-header [menu]="menu"></app-header>
    <nav class="nav-sticky">
      <app-categories [menu]="menu"></app-categories>
    </nav>
    <div class="content-fluid">
      <app-category></app-category>
    </div>
    <app-footer></app-footer>
  </div>
</ng-template>

<ng-template #pageError>
  <ng-container
    *ngIf="status === 404; then pageNotFound; else pageMessageError"
  ></ng-container>
</ng-template>

<ng-template #pageMessageError>
  <app-error [message]="message" [showButton]="error"></app-error>
</ng-template>

<ng-template #pageNotFound>
  <app-not-found message="El menú no existe"></app-not-found>
</ng-template>
