import { PaymentDto } from '@core/dtos';

export namespace ConsolidatePayment {
  export class Add {
    static readonly type = '[ConsolidatePayment] Add';
    constructor(public orderId: string) {}
  }

  export class Success {
    static readonly type = '[ConsolidatePayment] Success';
    constructor() {}
  }

  export class Failed {
    static readonly type = '[ConsolidatePayment] Failed';
    constructor(public message: string, public paid: boolean = null) {}
  }
}
