<div class="row cart-title-stick">
  <mat-icon class="col-xs-1 arrow-back" color="primary" (click)="dismiss()">arrow_back</mat-icon>
  <span class="cart-title col-xs-11">Mi pedido</span>
</div>
<ng-container *ngIf="products && products.size > 0">
  <div class="col-xs-12" *ngFor="let product of products | keyvalue">
    <app-card-cart [key]="product.key" [product]="product.value"></app-card-cart>
  </div>
</ng-container>
<ng-container *ngIf="!products || products.size < 1">
  <div class="col-xs-12 empty_cart">
    <p>Tu carrito está vacío <mat-icon>sentiment_dissatisfied</mat-icon></p>
  </div>
</ng-container>
<div class="row go-to-pay">
  <span class="col col-xs-5 total">Total: {{ total | currency }}</span>
  <button class="col col-xs-7" mat-raised-button [disabled]="total === 0" (click)="openOrderTypeSelector()">
    <span>Ir a confirmar</span>
  </button>
</div>
