import { ProductDto } from '../dtos';
import { Product } from '@core/models/product.model';

export function toProductDto(product: Product): ProductDto {
  return <ProductDto>{
    productId: product.productId,
    name: product.name,
    price: product.price,
  };
}
