<div class="content-fluid">
    <div class="row--not-margin">
        <div class="col-xs-12">
            <div class="box">
                <div class="not-found__top">{{ message }}</div>
                <div class="not-found__floor">404</div>
            </div>
        </div>
    </div>
</div>
