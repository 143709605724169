import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ConfirmOrder } from './confirm-order.actions';
import { OrderService } from '@core/https/order.service';
import { of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { CartService } from '@core/https/cart.service';
import { OrderDto } from '@core/dtos';

export class ConfirmOrderStateModel {
  success: boolean;
  error: {
    message: string;
  };
  loadingConfirmPointOfSale: boolean;
}

const defaults = {
  success: false,
  error: null,
  loadingConfirmPointOfSale: false,
};

@State<ConfirmOrderStateModel>({
  name: 'confirmOrder',
  defaults,
})
@Injectable()
export class ConfirmOrderState {
  constructor(
    private orderService: OrderService,
    private cartService: CartService
  ) {}

  @Selector()
  static isSuccess(state: ConfirmOrderStateModel): boolean {
    return state.success;
  }

  @Selector()
  static isSuccessFromPointOfSale(state: ConfirmOrderStateModel): boolean {
    return state.success;
  }

  @Selector()
  static isLoadingConfirmFromPointOfSale(
    state: ConfirmOrderStateModel
  ): boolean {
    return state.loadingConfirmPointOfSale;
  }

  @Selector()
  static isFailed(state: ConfirmOrderStateModel): boolean {
    return !state.success && !state.error.message;
  }

  @Action(ConfirmOrder.Confirm)
  confirm(
    { dispatch, patchState }: StateContext<ConfirmOrderStateModel>,
    { pointOfSale }: ConfirmOrder.Confirm
  ) {
    if (pointOfSale) {
      patchState({
        ...defaults,
        loadingConfirmPointOfSale: true,
      });
      return this.orderService.confirmFromPointOfSale().pipe(
        mergeMap(() => dispatch(new ConfirmOrder.Success())),
        catchError((error) => {
          dispatch(
            new ConfirmOrder.Failed(
              'Sucedió un error al tratar de confirmar tu orden.'
            )
          );
          console.error('Error at ConfirmOrder.Confirm', error);
          return of('');
        })
      );
    }
    return this.orderService.confirm().pipe(
      tap((order: OrderDto) =>
        this.cartService.setProductsFromArray(order.products)
      ),
      mergeMap(() => dispatch(new ConfirmOrder.Success())),
      catchError((error) => {
        dispatch(
          new ConfirmOrder.Failed(
            'Sucedió un error al tratar de confirmar tu orden.'
          )
        );
        console.error('Error at ConfirmOrder.Confirm', error);
        return of('');
      })
    );
  }

  @Action(ConfirmOrder.Success)
  success({ setState }: StateContext<ConfirmOrderStateModel>) {
    setState({
      success: true,
      error: null,
      loadingConfirmPointOfSale: false,
    });
  }

  @Action(ConfirmOrder.Failed)
  failed(
    { setState }: StateContext<ConfirmOrderStateModel>,
    { message }: ConfirmOrder.Failed
  ) {
    setState({
      success: false,
      error: {
        message,
      },
      loadingConfirmPointOfSale: false,
    });
  }
}
