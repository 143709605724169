import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreComponent } from '@client/core/core.component';
import { Select, Store } from '@ngxs/store';
import { CustomerInfoBottomSheetComponent } from '@shared/components/customer-info-bottom-sheet/customer-info-bottom-sheet.component';
import { CustomerIsSubscribedAction } from '@store/client/customer/customer.actions';
import { CustomerState } from '@store/client/customer/customer.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent extends CoreComponent {
  @Select(CustomerState.isSubscribed) isSubscribed$: Observable<boolean>;
  @Select(CustomerState.isSuccess) isSuccess$: Observable<boolean>;

  show: boolean = true;

  constructor(
    private customerInfoBottomSheet: MatBottomSheet,
    private store: Store,
    private _snackBar: MatSnackBar
  ) {
    super();
  }

  onInit(): void {
    this.store.dispatch(new CustomerIsSubscribedAction());

    this.isSubscribed$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isSubscribed: boolean) => {
        if (!isSubscribed) return;
        this.close();
      });

    this.isSuccess$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isSuccess: boolean) => {
        if (!isSuccess) return;
        this.showSnackBar('Suscripción agregada.');
      });
  }

  open() {
    this.customerInfoBottomSheet.open(CustomerInfoBottomSheetComponent, {
      panelClass: 'overflow--unset',
      hasBackdrop: true,
    });
  }

  close() {
    this.show = false;
  }

  private showSnackBar(message: string, duration: number = 3000) {
    this._snackBar.open(message, 'Cerrar', { duration });
  }
}
