<section *ngIf="!menu">
  <p>Cargando...</p>
</section>
<section *ngIf="menu">
  <div>
    <mat-list>
      <div mat-subheader>Selecciona el tipo de orden</div>
      <mat-list-item *ngIf="menu.allowOrderHere" (click)="goTo('HERE')">
        <mat-icon mat-list-icon>restaurant</mat-icon>
        <div mat-line>{{ 'HERE' | orderType }}</div>
      </mat-list-item>
      <mat-list-item *ngIf="menu.allowDelivery" (click)="goTo('DELIVERY')">
        <mat-icon mat-list-icon>delivery_dining</mat-icon>
        <div mat-line>{{ 'DELIVERY' | orderType }}</div>
      </mat-list-item>
      <mat-list-item *ngIf="menu.allowPickUp" (click)="goTo('PICK_UP')">
        <mat-icon mat-list-icon>storefront</mat-icon>
        <div mat-line>{{ 'PICK_UP' | orderType }}</div>
      </mat-list-item>
    </mat-list>
  </div>
</section>
