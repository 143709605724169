import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@core/helpers/keys.helper';
import { OrderService } from '@core/https/order.service';
import { environment } from '@env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalDataService } from './local-data.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private _SEGMENT = 'auth';
  private _KEY = '__ck';
  private _KEY_TOKEN = '__token';
  private _TEMP_VERSION: string = '__tempversion';

  static BUSINESS_TOKEN_POSITION = 0;
  static MENU_TOKEN_POSITION = 1;
  static VERSION_TOKEN_POSITION = 2;

  constructor(private http: HttpClient, private ldsvc: LocalDataService) {}

  login(key: string): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      skip: 'true',
    });
    const data = { key: key };
    this.updateCurrentKey(key);

    return this.http
      .post(`${environment.gateway}/${this._SEGMENT}/login`, data, {
        headers: headers,
        responseType: 'text',
      })
      .pipe(
        map((token: any) => {
          if (!token) throw new Error('No se pudó generar el token!');

          const idMenu: string =
            token.split('|')[SecurityService.MENU_TOKEN_POSITION];
          if (this.ldsvc.containsKey(`${this._KEY_TOKEN}${key}`)) {
            const currentToken: string = this.ldsvc.get<string>(
              `${this._KEY_TOKEN}${key}`
            );
            const currentIdMenu: string =
              currentToken.split('|')[SecurityService.MENU_TOKEN_POSITION];
            if (currentIdMenu !== idMenu) {
              this.localStorageClear();
              this.ldsvc.set<string>(`${this._KEY}`, key);
              this.ldsvc.set<string>(`${this._KEY_TOKEN}${key}`, token);
            }
          } else {
            this.ldsvc.set<string>(`${this._KEY_TOKEN}${key}`, token);
          }

          return idMenu;
        }),
        catchError((error) => throwError(error))
      );
  }

  getKey(): string {
    return this.ldsvc.get(this._KEY);
  }

  getTokenObs(): Observable<string> {
    return of(this.getToken());
  }

  getToken(): string {
    const currentKey: string = this.ldsvc.get(`${this._KEY}`);
    return this.ldsvc.get<string>(`${this._KEY_TOKEN}${currentKey}`);
  }

  getDataToken(position: number): string {
    const token = this.getToken();
    return token.split('|')[position];
  }

  refreshToken(version: number): void {
    const currentKey: string = this.ldsvc.get(`${this._KEY}`);
    const token: string = this.ldsvc.get<string>(
      `${this._KEY_TOKEN}${currentKey}`
    );
    const dataToken: string[] = token.split('|');

    if (
      parseInt(dataToken[SecurityService.VERSION_TOKEN_POSITION]) !== version
    ) {
      this.localStorageClear();
      this.ldsvc.set<string>(`${this._KEY}`, currentKey);
    }

    this.ldsvc.set(
      `${this._KEY_TOKEN}${currentKey}`,
      `${dataToken[SecurityService.BUSINESS_TOKEN_POSITION]}|${
        dataToken[SecurityService.MENU_TOKEN_POSITION]
      }|${version}`
    );
  }

  updateCurrentKey(key: string): void {
    if (this.ldsvc.containsKey(this._KEY)) {
      const currentKey = this.ldsvc.get<string>(this._KEY);
      if (key !== currentKey) this.localStorageClear();
    }

    this.ldsvc.set<string>(this._KEY, key);
  }

  setTempVersion(): void {
    this.ldsvc.set(this._TEMP_VERSION, 0);
  }

  removeTempVersion(): void {
    this.ldsvc.remove(this._TEMP_VERSION);
  }

  containsTempVersion(): boolean {
    return this.ldsvc.containsKey(this._TEMP_VERSION);
  }

  private localStorageClear() {
    const personalInfo: string = localStorage.getItem(Keys.PERSONAL_INFO);
    const subscribed: string = localStorage.getItem(Keys.SUBSCRIBED);
    localStorage.clear();
    if (personalInfo) localStorage.setItem(Keys.PERSONAL_INFO, personalInfo);
    if (subscribed) localStorage.setItem(Keys.SUBSCRIBED, subscribed);
  }
}
