<div *ngIf="loading" class="col-xs-12 loading-products">
  <app-loading message="Cargando productos"></app-loading>
</div>
<div class="row my-products">
  <div class="col-xs-12">
    <mat-list *ngIf="!loading">
      <div mat-subheader>Mis productos</div>
      <app-product-detail *ngFor="let product of products" [product]="product"></app-product-detail>
      <mat-list-item>
        <div mat-line *ngIf="menu && menu.deliveryFee > 0 && this.orderType && this.orderType === 'DELIVERY'">
          <span class="col-xs-12 total">Envio: {{ menu.deliveryFee | currency }}</span>
        </div>
        <div mat-line>
          <span class="col-xs-12 total">Total: {{ total | currency }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
