import { Injectable } from '@angular/core';
import { OrderProduct } from '@core/models/order-product.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CartService } from 'core/https/cart.service';

import {
  CartAddAction,
  CartChangeQuantityAction,
  CartGetAction,
  CartGetTotalAction,
} from '../actions/cart.actions';
import { of, throwError } from 'rxjs';
import { OrderService } from '@core/https/order.service';
import {
  AddProductDto,
  OrderDto,
  ProductDto,
  SaveOrderDto,
} from '../../../../core/dtos';

export class CartStateModel {
  public products: Map<string, ProductDto>;
  public total: number;
  public addProductLoading: boolean;
}

const defaults = {
  products: null,
  total: 0,
  addProductLoading: false,
};

@State<CartStateModel>({
  name: 'cart',
  defaults,
})
@Injectable()
export class CartState {
  constructor(
    private cartSvc: CartService,
    private orderService: OrderService
  ) {}

  @Selector()
  public static getTotal({ total }) {
    return total;
  }

  @Selector()
  public static get({ products }) {
    return products;
  }

  @Selector()
  public static getAddProductLoading({ addProductLoading }) {
    return addProductLoading;
  }

  @Action(CartAddAction)
  add(
    { patchState, dispatch }: StateContext<CartStateModel>,
    { productDto }: CartAddAction
  ) {
    let products: Map<string, ProductDto> = this.cartSvc.getProducts();
    patchState({ addProductLoading: true });
    if (!products) {
      return this.orderService.create().pipe(
        switchMap((createdOrder) => {
          const addProduct = this.buildAddProduct(createdOrder, productDto);
          return this.orderService.addProduct({ addProduct }).pipe(
            tap(() => {
              const products = this.cartSvc.getProducts();
              patchState({
                products,
                addProductLoading: false,
              });
              dispatch(new CartGetTotalAction());
            }),
            catchError((_) => {
              console.error('__', _);
              patchState({
                addProductLoading: false,
              });
              return of();
            })
          );
        }),
        catchError((error) => throwError(error))
      );
    }
    const currentOrder = this.orderService.getCurrentOrder();
    const addProduct = this.buildAddProduct(currentOrder, productDto);
    return this.orderService.addProduct({ addProduct }).pipe(
      tap(() => {
        const products = this.cartSvc.getProducts();
        patchState({
          products,
          addProductLoading: false,
        });
        dispatch(new CartGetTotalAction());
      }),
      catchError((_) => {
        console.error('__', _);
        patchState({
          addProductLoading: false,
        });
        return of();
      })
    );
  }

  @Action(CartGetTotalAction)
  getTotal(ctx: StateContext<CartStateModel>) {
    let products: Map<string, ProductDto> = this.cartSvc.getProducts();
    if (!products) {
      products = new Map<string, ProductDto>();
    }
    let total = this.cartSvc.getTotal(products);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      total: total,
    });
  }

  @Action(CartGetAction)
  get(ctx: StateContext<CartStateModel>) {
    let products: Map<string, ProductDto> = this.cartSvc.getProducts();
    if (!products) {
      products = new Map<string, ProductDto>();
    }
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      products: products,
    });
  }

  @Action(CartChangeQuantityAction)
  changeQuantity(
    { patchState }: StateContext<CartStateModel>,
    { id, quantity }: CartChangeQuantityAction
  ) {
    const currentOrder = this.orderService.getCurrentOrder();
    let products: Map<string, ProductDto> = this.cartSvc.getProducts();
    const product = products.get(id);
    product.quantity += quantity;
    if (product.quantity < 1) {
      products.delete(id);
    }
    this.cartSvc.setProducts(products);
    let total = this.cartSvc.getTotal(products);
    patchState({
      products,
      total,
    });

    if (product.quantity < 1) {
      const removeProduct = this.buildRemoveProduct(currentOrder, product);
      return this.orderService.removeProduct({ removeProduct }).pipe(
        catchError((_) => {
          console.error('__', _);
          return of();
        })
      );
    }

    const updateProduct = this.buildUpdateProduct(currentOrder, product);
    return this.orderService.updateProduct({ updateProduct }).pipe(
      catchError((_) => {
        console.error('__', _);
        return of();
      })
    );
  }

  private buildAddProduct(order: OrderDto, productDto: ProductDto) {
    return <AddProductDto>{
      order: <SaveOrderDto>{
        orderId: order.orderId,
        status: 'ADDED_PRODUCT',
        product: productDto,
      },
    };
  }

  private buildUpdateProduct(order: OrderDto, productDto: ProductDto) {
    return <AddProductDto>{
      order: <SaveOrderDto>{
        orderId: order.orderId,
        status: 'UPDATED_PRODUCT',
        product: productDto,
      },
    };
  }

  private buildRemoveProduct(order: OrderDto, productDto: ProductDto) {
    return <AddProductDto>{
      order: <SaveOrderDto>{
        orderId: order.orderId,
        status: 'REMOVED_PRODUCT',
        product: productDto,
      },
    };
  }
}
