import { Injectable } from '@angular/core';
import { Extra } from '@core/models/extra.model';
import { Product } from '@core/models/product.model';
import { LocalDataService } from '@core/services';
import { ApiFacadeService } from '@core/services/api-facade.service';
import { SecurityService } from '@core/services/security.service';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _SEGMENT: string = 'products';
  private _KEY: string = '__p';

  constructor(
    private afsvc: ApiFacadeService,
    private ldsvc: LocalDataService,
    private securitySvc: SecurityService
  ) {}

  getFromLocal(productId: string) {
    return this.ldsvc.getObs<Product>(`${this._KEY}${productId}`);
  }

  get(productId: string): Observable<Product> {
    return this.ldsvc.containsKeyObs(`${this._KEY}${productId}`).pipe(
      tap((exists) => {
        if (!exists) {
          this.securitySvc.setTempVersion();
        }
      }),
      switchMap(() =>
        this.afsvc
          .get<Product>({
            segment: `${this._SEGMENT}/${productId}`,
            storageOptions: {
              search: false,
              save: false,
              key: `${this._KEY}${productId}`,
            },
            isOldGateway: false,
          })
          .pipe(
            switchMap((product: Product) =>
              iif(
                () => !product,
                this.getFromLocal(productId),
                of(product).pipe(
                  tap(() => this.securitySvc.removeTempVersion()),
                  map((product: Product) => {
                    this.ldsvc.set<Product>(`${this._KEY}${productId}`, product);
                    this.securitySvc.refreshToken(product.version);
                    this._sortExtras(product);
                    return product;
                  })
                )
              )
            )
          )
      ),
      catchError((error) => throwError(error))
    );
  }

  private _sortExtras(product: Product) {
    if (!product.extras || !product.extras.length) return;

    let extrasObject = product.extras.reduce(
      (prev, current) => ({
        ...prev,
        [current.extraId]: current,
      }),
      {}
    );
    var extras: Extra[] = [];
    for (const o of product.sort) {
      let extra: Extra = extrasObject[o];
      if (extra) {
        extras.push(extra);
      }
    }
    product.extras = extras;
  }
}
