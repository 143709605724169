import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CoreComponent } from '@client/core/core.component';
import { OrderRequested } from '@core/models/order-requested.model';
import { OrderQrBottomSheetComponent } from '../order-qr-bottom-sheet/order-qr-bottom-sheet.component';

@Component({
  selector: 'app-my-products-detail',
  templateUrl: './my-products-detail.component.html',
  styleUrls: ['./my-products-detail.component.scss'],
})
export class MyProductsDetailComponent extends CoreComponent {
  @Input() order: OrderRequested;

  total: number;

  constructor(private qrBottomSheet: MatBottomSheet) {
    super();
  }

  onInit(): void {
    this.total = 0; //--this.order.order.products.reduce((p, c) => (p += c.price), 0);
  }

  openQr(): void {
    this.qrBottomSheet.open(OrderQrBottomSheetComponent, {
      hasBackdrop: true,
      panelClass: 'bottom-sheet-no-padding',
      data: { id: this.order.order.orderId },
    });
  }
}
